import './styles.scss'

import { CustomerType, UserStatus } from 'api/types'
import Spinner from 'components/Spinner'
import AuthContext from 'contexts/AuthContext'
import useEmailConfirmation from 'hooks/useEmailConfirmation'
import { useContext, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

const UserEmailConfirmation = () => {
  const history = useHistory()
  const { search } = useLocation()
  const uid = new URLSearchParams(search).get('uid')
  const { uid: userId, status, customerType } = useContext(AuthContext)
  const { isLoading } = useEmailConfirmation(userId, {
    skip: !uid || uid !== userId || status !== UserStatus.CREATED
  })

  useEffect(() => {
    if (isLoading) return

    if (!uid && status === UserStatus.CREATED) {
      history.replace('/confirmation')
    } else if (
      customerType === CustomerType.SDK ||
      customerType === CustomerType.PROXY_ENTERPRISE
    ) {
      window.location.href = '/dashboard'
    } else if (customerType === CustomerType.PROXY_SELFSERVICE) {
      window.location.href = '/checkout'
    }
  }, [history, uid, status, customerType, isLoading])

  return (
    <div className='user-email-confirmation'>
      <Spinner />
    </div>
  )
}

export default UserEmailConfirmation
