import 'moment-timezone'

import moment from 'moment'

export const today = () => moment().startOf('day')

export const yesterday = () => moment().subtract(1, 'days').startOf('day')

export const lastWeek = () => moment().subtract(1, 'weeks').startOf('day')

export const lastMonth = () => moment().subtract(1, 'months').startOf('day')

export const lastQuarter = () => moment().subtract(1, 'quarters').startOf('day')

export const lastYear = () => moment().subtract(1, 'years').startOf('day')

export const isoToLocalDate = date => moment(date).format('YYYY/MM/DD')

export const isoToLocalTime = time =>
  moment
    .utc(time, moment.HTML5_FMT.TIME_MS)
    .local()
    .startOf('hour')
    .format('h:mma')

export const isoToPacificTime = time =>
  moment
    .utc(time, moment.HTML5_FMT.TIME_MS)
    .tz('America/Los_Angeles')
    .startOf('hour')
    .format('h:mma')

export const formatTimestamp = time =>
  moment
    .utc(time, moment.HTML5_FMT.TIME_MS)
    .tz('America/Los_Angeles')
    .format('h:mma')

export const formatChartDate = dateString =>
  moment(dateString, 'YYYY/MM/DD').format('MM/DD/YYYY')

export const formatTableDate = dateString =>
  moment(dateString, 'YYYY/MM/DD').format('MM/DD/YY')

export const startOfMonth = dateString =>
  moment(dateString, 'YYYY-MM').startOf('month')

export const endOfMonth = dateString =>
  moment(dateString, 'YYYY-MM').endOf('month')

export const formatReportDate = dateString =>
  moment(dateString, 'YYYY-MM').format('MMMM, YYYY')

export const isAfter = (date, limit) => moment(date).isAfter(limit, 'day')

export const isSame = (first, second) => moment(first).isSame(second)

export const convertFirstDate = first => moment(first, 'YYYY/MM/DD')

const toDate = timestamp =>
  new Date(timestamp.getFullYear(), timestamp.getMonth(), timestamp.getDate())

export const calculateExpiration = (todayTimestamp, expiresAt) => {
  const MILLISECONDS_PER_DAY = 1000 * 60 * 60 * 24

  const expires = new Date(expiresAt)

  const diffTime = toDate(expires) - toDate(todayTimestamp)

  return Math.ceil(diffTime / MILLISECONDS_PER_DAY)
}

export const calculateExpirationDay = expiresAt => {
  const expires = new Date(expiresAt)
  const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  }

  return expires.toLocaleDateString('default', options)
}
