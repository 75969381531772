import './styles.scss'

import {
  getAccount,
  getAllUserPlans,
  getPastInvoices,
  getUpcomingInvoice
} from 'api/admin/backend'
import { fetchPartner } from 'api/admin/firebase'
import { CustomerType } from 'api/types'
import Actions from 'components/Actions'
import PartnerLayout from 'components/Layouts/PartnerLayout'
import ArrowRightIcon from 'components/SVGs/ArrowRightIcon'
import { useCallback, useEffect, useState } from 'react'
import NotificationManager from 'react-notifications'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import { InvoicesTab, MoreInfoTab, PlansTab, UserTab } from './tabs'

const UserProfile = () => {
  const { uid } = useParams()
  const history = useHistory()
  const location = useLocation()
  const [user, setUser] = useState(null)
  const [userAccount, setUserAccount] = useState(null)
  const [userPlans, setUserPlans] = useState([])
  const [pastInvoices, setPastInvoices] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [upcomingInvoice, setUpcomingInvoice] = useState(null)
  const [activeTab, setActiveTab] = useState('user')

  const fetchUser = useCallback(async () => {
    try {
      const userProfile = await fetchPartner(uid)

      setUser(userProfile)
    } catch (error) {
      NotificationManager.error(error.message, 'Failed to fetch user profile')
    }
  }, [uid])

  useEffect(() => {
    const fetchAndSetUserData = async () => {
      try {
        setUser(await fetchPartner(uid))

        setIsLoading(true)

        const [
          fetchedAllUserPlans,
          fetchedPastInvoices,
          fetchedUpcomingInvoice,
          fetchedUserAccount
        ] = await Promise.all([
          getAllUserPlans(uid),
          getPastInvoices(uid),
          getUpcomingInvoice(uid),
          getAccount(uid)
        ])

        setUserPlans(fetchedAllUserPlans)
        setPastInvoices(fetchedPastInvoices)
        setUpcomingInvoice(fetchedUpcomingInvoice)
        setUserAccount(fetchedUserAccount)
      } catch (error) {
        NotificationManager.error(error.message, 'Failed to fetch user data')
      } finally {
        setIsLoading(false)
      }
    }

    fetchAndSetUserData()
  }, [uid, setUserPlans, setPastInvoices, setUpcomingInvoice])

  if (!user) {
    return null
  }

  const { customerType } = user

  const TabContent = () => {
    switch (activeTab) {
      case 'user':
        return (
          <UserTab
            isLoading={isLoading}
            proxyUsername={userAccount?.username}
            {...user}
          />
        )
      case 'moreInfo':
        return <MoreInfoTab user={user} />
      case 'plans':
        return <PlansTab userId={uid} userPlans={userPlans} {...user} />
      case 'invoices':
        return (
          <InvoicesTab
            arePastInvoicesLoading={isLoading}
            {...{
              pastInvoices,
              upcomingInvoice
            }}
          />
        )
      default:
        return null
    }
  }

  return (
    <PartnerLayout>
      <div className='user-profile-container'>
        <div className='user-profile-controls'>
          <button
            type='button'
            className='button'
            onClick={() => {
              const { currentPage } = location.state || { currentPage: 0 }

              history.push({
                pathname: '/admin/dashboard',
                state: { currentPage }
              })
            }}
          >
            <span
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'right',
                transform: 'scaleX(-1)',
                width: '20px'
              }}
            >
              <ArrowRightIcon width={14} height={14} color='#fff' />
            </span>
            Back to Dashboard
          </button>
          <Actions partner={user} refreshPartners={fetchUser} />
        </div>

        <h2>User Profile</h2>

        <div className='tabs'>
          <button
            type='button'
            className={`tab ${activeTab === 'user' ? 'active' : ''}`}
            onClick={() => setActiveTab('user')}
          >
            User
          </button>
          {customerType === CustomerType.PROXY_SELFSERVICE && (
            <button
              type='button'
              className={`tab ${activeTab === 'moreInfo' ? 'active' : ''}`}
              onClick={() => setActiveTab('moreInfo')}
            >
              More Info
            </button>
          )}
          {customerType !== CustomerType.SDK && (
            <>
              <button
                type='button'
                className={`tab ${activeTab === 'plans' ? 'active' : ''}`}
                onClick={() => setActiveTab('plans')}
              >
                User&rsquo;s Plans
              </button>
              <button
                type='button'
                className={`tab ${activeTab === 'invoices' ? 'active' : ''}`}
                onClick={() => setActiveTab('invoices')}
              >
                Invoices
              </button>
            </>
          )}
        </div>

        <div className='user-details'>
          <TabContent />
        </div>
      </div>
    </PartnerLayout>
  )
}

export default UserProfile
