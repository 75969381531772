import axios from 'axios'
import { NotificationManager } from 'react-notifications'

import { getCurrentToken } from './user/firebase'

// Returns the base URL for the backend network API.
// For local development, the following environment variable can be omitted in the .env file
// if the 'proxy' value is specified in package.json
const getBaseUrl = () =>
  process.env.REACT_APP_BACKEND_API_NETWORK_URL || '/dashboard/v1'

const http = axios.create({
  baseURL: getBaseUrl(),
  withCredentials: false
})

http.interceptors.request.use(
  async config => {
    const token = await getCurrentToken()

    if (token) {
      config.headers.authorization = `Bearer ${token}`
    }

    return config
  },
  error => {
    // eslint-disable-next-line no-console
    console.error(error)

    NotificationManager.error(
      error.message,
      'Something went wrong – please try again.'
    )

    throw new Error(error)
  }
)

export default http
