import { UserStatus } from 'api/types'
import {
  equalTo,
  get,
  getDatabase,
  limitToLast,
  orderByChild,
  query,
  ref,
  update
} from 'firebase/database'
import firebaseApp from 'utils/firebase'

import CustomerType from '../types/CustomerType'
import { sendWelcomeEmail } from './lambda'

const db = getDatabase(firebaseApp)

export const isAdmin = uid => uid === process.env.REACT_APP_ADMIN_UID

export const fetchPartner = async uid => {
  const partnersRef = ref(db, `partners/${uid}`)
  const snapshot = await get(partnersRef)

  if (snapshot.exists()) {
    const values = snapshot.val()

    return {
      uid,
      ...values
    }
  }

  return null
}

// TODO: Should be replaced by the function with search filters
export const fetchBankedProxyEnterprisePartners = async () => {
  const partnersRef = ref(db, `partners`)
  const snapshot = await get(partnersRef)
  const partnersData = snapshot.val()

  const filteredPartners = []

  if (partnersData) {
    Object.entries(partnersData).forEach(([key, partner]) => {
      if (
        (partner.status === UserStatus.BANKED ||
          partner.status === UserStatus.TRIAL) &&
        partner.customerType === CustomerType.PROXY_ENTERPRISE
      ) {
        filteredPartners.push({ value: key, label: partner.email })
      }
    })
  }

  return filteredPartners
}

const snapshotToArray = snapshot => {
  const result = []

  snapshot.forEach(childSnapshot => {
    result.push({ uid: childSnapshot.key, ...childSnapshot.val() })
  })

  return result
}

export const fetchPartners = async ({ email, order, limit, currentPage }) => {
  const partnersRef = ref(db, 'partners')
  const startAt = email ? 0 : currentPage * limit

  let q

  if (email) {
    q = query(partnersRef, orderByChild('email'), equalTo(email))
  } else {
    q = query(
      partnersRef,
      orderByChild('utcTimestamp'),
      limitToLast(startAt + limit)
    )
  }

  const snapshot = await get(q)

  if (!snapshot.exists()) {
    return []
  }

  const partners = snapshotToArray(snapshot).filter(partner => !partner.ignored)

  const sortedPartners = order === 'desc' ? partners.reverse() : partners

  return sortedPartners.slice(startAt, startAt + limit)
}

export const updatePartnerStatus = async (
  uid,
  status,
  shouldSendWelcomeEmail = true
) => {
  if (shouldSendWelcomeEmail && status === UserStatus.BANKED) {
    sendWelcomeEmail(uid)
  }

  const partnersRef = ref(db, `partners/${uid}`)

  return update(partnersRef, {
    status
  })
}

export const approveTrialPartner = async (
  uid,
  shouldSendWelcomeEmail = true
) => {
  return updatePartnerStatus(uid, UserStatus.TRIAL, shouldSendWelcomeEmail)
}

export const approvePartner = async (uid, shouldSendWelcomeEmail = true) => {
  return updatePartnerStatus(uid, UserStatus.BANKED, shouldSendWelcomeEmail)
}

export const rejectPartner = uid => {
  return updatePartnerStatus(uid, UserStatus.REJECTED)
}

export const ignorePartner = uid => {
  return updatePartnerStatus(uid, UserStatus.IGNORED)
}

export const pausePartner = uid => {
  return updatePartnerStatus(uid, UserStatus.PAUSED)
}
