import 'react-phone-input-2/lib/style.css'
import './styles.scss'

import { sendOtp } from 'api/user/backend'
import Button from 'components/Button'
import React, { useState } from 'react'
import PhoneInput from 'react-phone-input-2'

const FormPhoneInput = React.forwardRef(
  ({ onChange, value, setVerificationSid, errorMessage, ...props }, ref) => {
    const [phoneNumber, setPhoneNumber] = useState('')
    const [sendingStatus, setSendingStatus] = useState('NONE')

    const handlePhoneNumberChange = number => {
      const formattedNumber = number.startsWith('+') ? number : `+${number}`

      setPhoneNumber(formattedNumber)
      onChange(formattedNumber)
    }

    const handleSendCodeClick = async () => {
      setSendingStatus('SENDING')

      try {
        const verificationSid = await sendOtp(phoneNumber)

        if (!verificationSid) {
          throw new Error('Failed to send code.')
        }

        setVerificationSid(verificationSid)
        setSendingStatus('SENT')
      } catch (error) {
        setSendingStatus('ERROR')
      }
    }

    const getSendingStatus = () => {
      switch (sendingStatus) {
        case 'SENDING':
          return 'Sending code... Please wait.'
        case 'SENT':
          return 'Code sent. Please enter it below.'
        case 'ERROR':
          return 'Something went wrong. Please try again.'
        case 'NONE':
        default:
          return ''
      }
    }

    const getButtonLabel = () => {
      return sendingStatus === 'SENT' || sendingStatus === 'ERROR'
        ? 'Verify Again'
        : 'Verify'
    }

    return (
      <>
        <div className='form-phone-input'>
          <PhoneInput
            ref={ref}
            className='form-phone-input__input'
            {...props}
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
          />
          <Button
            type='button'
            className='button additional-info-form__button'
            label={getButtonLabel()}
            onClick={handleSendCodeClick}
            inline
          />
          <span className='form-phone-input__status-label'>
            {getSendingStatus()}
          </span>
        </div>
        {errorMessage && <div className='form-item__error'>{errorMessage}</div>}
      </>
    )
  }
)

export default FormPhoneInput
