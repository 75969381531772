import { getUserAccount, getUserUpcomingInvoice } from '../common'
import http from '../http'

export const getAvailablePlans = async () => {
  const { data: plans } = await http.get(`/plans`)

  return plans || []
}

export const getPastInvoices = async () => {
  const { data: invoices } = await http.get('/invoices')

  return invoices || []
}

export const getUpcomingInvoice = async () =>
  getUserUpcomingInvoice('/upcoming-invoice')

export const getAllPlans = async () => {
  const { status, data: plans } = await http.get('/all-plans')

  return status === 200 && plans && plans.length > 0 ? plans : []
}

export const getAccount = async () => getUserAccount('/account')

export const resetApiKey = async () => {
  const response = await http.post('/account/reset-apikey')
  const { proxy_username: username, proxy_api_key: apiKey } = response.data

  return {
    username,
    apiKey
  }
}

export const createCheckoutSession = async (planId, autoRenew) => {
  const response = await http.post('/checkout-session', {
    plan_id: planId,
    auto_renew: autoRenew
  })

  return response?.data
}

export const getTrxData = async sessionId => {
  const { data } = await http.get(`/checkout-session?session_id=${sessionId}`)

  if (data && data.id === sessionId && data.status === 'complete') {
    return {
      chargeId: data.stripe_charge_id,
      amount: data.amount_charged,
      customerId: data.stripe_customer_id
    }
  }

  return null
}

export const sendOtp = async phoneNumber => {
  const response = await http.post('/account/send-otp', {
    phone_number: phoneNumber
  })

  const { status, data } = response

  return status === 200 && data?.verification_sid ? data.verification_sid : null
}

export const checkOtp = async (verificationSid, code) => {
  const data = {
    verification_sid: verificationSid,
    code
  }

  const { status } = await http.post('/account/check-otp', data)

  return status === 200
}

export const disableAutoRenew = async () => {
  const { status } = await http.post('/disable-autorenew')

  return status === 200
}
