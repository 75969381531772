import { useEffect } from 'react'
import ReactGA from 'react-ga'
import { useLocation } from 'react-router-dom'

const TRACKING_ID = 'UA-135690027-4'

const init = () => {
  ReactGA.initialize(TRACKING_ID)
}

const sendPageview = path => {
  ReactGA.set({ page: path })
  ReactGA.pageview(path)
}

const useAnalytics = () => {
  const location = useLocation()

  useEffect(() => {
    init()
  }, [])

  useEffect(() => {
    const { pathname, search, hash } = location
    const currentPath = pathname + search + hash

    sendPageview(currentPath)
  }, [location])
}

export default useAnalytics
