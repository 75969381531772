const PlanIcon = ({ className, ariaHidden = true }) => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    aria-hidden={ariaHidden}
    className={className}
  >
    <path
      d='M11 0H3a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2 2 2 0 0 0 2-2V4a2 2 0 0 0-2-2 2 2 0 0 0-2-2Zm2 3a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1V3ZM2 2a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V2Z'
      fill='#111014'
    />
  </svg>
)

export default PlanIcon
