import './styles.scss'

import DesktopLogo from 'components/SVGs/DesktopLogo'

const LogoTitle = () => (
  <div className='logo-title'>
    <a
      href='https://joinmassive.com/'
      target='_blank'
      rel='noopener noreferrer'
    >
      <DesktopLogo width={220} height={45} />
    </a>
  </div>
)

export default LogoTitle
