import './styles.scss'

import clsx from 'clsx'

const FormItem = ({
  label,
  className,
  hint,
  htmlFor,
  required = false,
  children,
  style,
  errorMessage,
  centerControl = false
}) => {
  const classes = clsx('form-item', className, [
    required && 'form-item--required',
    !!hint && 'form-item--with-hint'
  ])

  const controlClasses = clsx('form-item__control', {
    'form-item__control--centered': centerControl
  })

  const LabelTag = htmlFor ? 'label' : 'div'

  return (
    <div className={classes}>
      {label && (
        <LabelTag htmlFor={htmlFor} className='form-item__label'>
          {label}
        </LabelTag>
      )}
      {hint && <div className='form-item__hint'>{hint}</div>}
      <div className={controlClasses} style={style}>
        {children}
      </div>
      {errorMessage && <div className='form-item__error'>{errorMessage}</div>}
    </div>
  )
}

export default FormItem
