import '../styles.scss'

import { CustomerType } from 'api/types'
import { formatTableDate } from 'utils/dates'

const UserTab = ({
  isLoading,
  proxyUsername,
  firstName,
  lastName,
  email,
  utcTimestamp,
  customerType,
  status,
  planId: initialPlanId
}) => {
  return (
    <div className='tab-content'>
      <p>
        <strong>First Name:</strong> {firstName}
      </p>
      <p>
        <strong>Last Name:</strong> {lastName}
      </p>
      <p>
        <strong>Email:</strong> <a href={`mailto:${email}`}>{email}</a>
      </p>
      <p>
        <strong>Signup Date:</strong> {formatTableDate(utcTimestamp)}
      </p>
      <p>
        <strong>Customer Type:</strong> {customerType}
      </p>
      <p>
        <strong>Status:</strong> {status}
      </p>
      {(customerType === CustomerType.PROXY_SELFSERVICE ||
        customerType === CustomerType.PROXY_ENTERPRISE) && (
        <p>
          <strong>Proxy Username:</strong>{' '}
          {isLoading
            ? 'Loading...'
            : proxyUsername
            ? proxyUsername.split('. ')[0]
            : 'N/A'}
        </p>
      )}
      {customerType === CustomerType.PROXY_SELFSERVICE && (
        <p>
          <strong>Initial Plan ID:</strong> {initialPlanId}
        </p>
      )}
    </div>
  )
}

export default UserTab
