import { getAllPlans } from 'api/admin/backend'
import { useEffect, useState } from 'react'

const LOCAL_STORAGE_KEY = 'admin_plans'

const fetchPlans = async (
  setPlans,
  setError,
  setIsLoading,
  storedPlansString
) => {
  try {
    setIsLoading(true)

    const fetchedPlans = await getAllPlans()
    const fetchedPlansString = JSON.stringify(fetchedPlans)

    if (storedPlansString !== fetchedPlansString) {
      setPlans(fetchedPlans)
      localStorage.setItem(LOCAL_STORAGE_KEY, fetchedPlansString)
    }
  } catch (err) {
    setError(err)
  } finally {
    setIsLoading(false)
  }
}

const useAllPlans = () => {
  const storedPlans = localStorage.getItem(LOCAL_STORAGE_KEY)

  const [plans, setPlans] = useState(storedPlans ? JSON.parse(storedPlans) : [])
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)

  useEffect(() => {
    fetchPlans(setPlans, setError, setIsLoading, storedPlans).then()
  }, [storedPlans])

  return { plans, isLoading, error }
}

export default useAllPlans
