const DashboardIcon = ({ className, ariaHidden = true }) => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    aria-hidden={ariaHidden}
    className={className}
  >
    <g fill='#000'>
      <path d='M3 13h8V3H3v10Zm2-8h4v6H5V5ZM3 21h8v-6H3v6Zm2-4h4v2H5v-2ZM13 21h8V11h-8v10Zm2-8h4v6h-4v-6ZM13 3v6h8V3h-8Zm2 4V5h4v2h-4Z' />
    </g>
  </svg>
)

export default DashboardIcon
