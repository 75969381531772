/* eslint-disable react/button-has-type */
import './styles.scss'

import clsx from 'clsx'

const Button = ({ className, type, label, inline, ...props }) => {
  const classes = clsx('button', className, [inline && 'button--inline'])

  return (
    <button type={type} className={classes} {...props}>
      {label}
    </button>
  )
}

export default Button
