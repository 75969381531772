import './styles.scss'

import { logout } from 'api/user/firebase'
import LogoutIcon from 'components/SVGs/LogoutIcon'
import AuthContext from 'contexts/AuthContext'
import getNavigationItems from 'navigation'
import { useCallback, useContext } from 'react'
import { NavLink, useLocation } from 'react-router-dom'

const RenderNavLink = ({
  name,
  href,
  icon: Icon,
  isExternal = false,
  setIsShow
}) => {
  const { pathname } = useLocation()

  const checkCurrentLocation = () => {
    const el = document.getElementsByClassName('partner-layout__content')

    if (href === pathname) {
      el?.[0].classList.remove('modal-open')
      setIsShow(false)
    }
  }

  if (isExternal) {
    return (
      <a
        key={name}
        className='nav-link'
        href={href}
        target='_blank'
        rel='noopener noreferrer'
      >
        <Icon className='nav-link__icon' />
        {name}
      </a>
    )
  }

  return (
    <NavLink
      key={name}
      className='nav-link'
      to={href}
      activeClassName='nav-link--active'
      onClick={() => checkCurrentLocation()}
    >
      <Icon className='nav-link__icon' />
      {name}
    </NavLink>
  )
}

const ModalView = ({ setIsShow }) => {
  const { email, isAdmin, customerType } = useContext(AuthContext) || {}

  const handleLogout = useCallback(async event => {
    event.preventDefault()
    await logout()
    window.location.reload()
  }, [])

  return (
    <div className='modal'>
      <div className='modal__nav__wrapper'>
        <nav className='modal__nav'>
          {getNavigationItems(email, isAdmin, customerType).map(link => {
            return (
              <RenderNavLink setIsShow={setIsShow} key={link.name} {...link} />
            )
          })}
        </nav>
        {email ? (
          <div className='modal__email__wrapper'>
            <span className='modal__email'>{email}</span>
            <a className='' href='/' onClick={handleLogout}>
              <LogoutIcon className='nav-link__icon' />
              Log out
            </a>
          </div>
        ) : (
          <NavLink
            className='modal__login'
            to='/login'
            activeClassName='nav-button--active'
          >
            Login
          </NavLink>
        )}
      </div>
    </div>
  )
}

export default ModalView
