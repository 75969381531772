export const formatCurrency = (
  value,
  minDigit,
  maxDigit,
  removeTrailingZeros = false
) => {
  if (!value) return ''

  const fractionDigits = value > 1 ? minDigit : maxDigit
  let formattedValue = parseFloat(value.toFixed(2))
    .toLocaleString('en-US', {
      minimumFractionDigits: fractionDigits,
      maximumFractionDigits: fractionDigits
    })
    .replace('-0.00', '0.00')

  if (removeTrailingZeros) {
    formattedValue = parseFloat(formattedValue).toString()
  }

  return `$${formattedValue}`
}

export const formatCurrencyRemovingTrailingZeros = (
  value,
  minDigit,
  maxDigit
) => {
  return formatCurrency(value, minDigit, maxDigit, true)
}
