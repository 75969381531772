import { confirmRegistration } from 'api/user/lambda'
import { useEffect, useState } from 'react'

const fetchEmailConfirmation = async (
  uid,
  setEmailConfirmation,
  setError,
  setIsLoading,
  skip
) => {
  try {
    setIsLoading(true)

    if (skip) {
      return
    }

    const response = await confirmRegistration(uid)

    setEmailConfirmation(response.data)

    window.location.reload() // update the user status
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err)
    setError(err)
  } finally {
    setIsLoading(false)
  }
}

const useEmailConfirmation = (uid, { skip }) => {
  const [emailConfirmation, setEmailConfirmation] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    fetchEmailConfirmation(
      uid,
      setEmailConfirmation,
      setError,
      setIsLoading,
      skip
    ).then()
  }, [skip, uid])

  return { emailConfirmation, isLoading, error }
}

export default useEmailConfirmation
