const BurgerMenu = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 21 22'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect
      x='0.493912'
      y='18.9395'
      width='26'
      height='3'
      rx='1.5'
      transform='rotate(-45 0.493912 18.9395)'
      fill='black'
    />
    <rect
      x='2.49391'
      y='0.939453'
      width='26'
      height='3'
      rx='1.5'
      transform='rotate(45 2.49391 0.939453)'
      fill='black'
    />
  </svg>
)

export default BurgerMenu
