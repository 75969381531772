/* eslint-disable react/no-unknown-property */

import { CustomerType, UserStatus } from 'api/types'
import { removePendingPlanId } from 'api/user/firebase'
import Banner from 'components/common/Banner'
import Spinner from 'components/Spinner'
import AuthContext from 'contexts/AuthContext'
import useUserAllPlans from 'hooks/useUserAllPlans'
import { useContext, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { getResidentialProxyApiDocsUrl } from 'services/Mintlify'
import { trackConversion } from 'services/Tapfiliate'

import UserPlans from '../UserPlans'

const YourPlan = () => {
  const { search } = useLocation()
  const queryParams = new URLSearchParams(search)
  const stripeSessionId = queryParams.get('session_id')
  const history = useHistory()

  const { plans, isLoading: arePlansLoading } = useUserAllPlans()
  const {
    uid: userId,
    status,
    customerType,
    additionalInfo,
    pendingPlanId
  } = useContext(AuthContext)

  useEffect(() => {
    if (status === UserStatus.CREATED) {
      history.replace('/confirmation')
    }
  }, [status, history])

  useEffect(() => {
    const handleSuccessCheckout = async () => {
      if (stripeSessionId && userId) {
        try {
          await trackConversion(stripeSessionId)
          await removePendingPlanId(userId)
        } catch (error) {
          // Error is caught but ignored, so no action is taken.
        }
      }
    }

    handleSuccessCheckout()
  }, [stripeSessionId, userId])

  const plansLoaded = !arePlansLoading
  const hasPlans = plans?.length > 0
  const hasActivePlans = plans?.some(plan => plan.active)

  return (
    <>
      {arePlansLoading && <Spinner />}

      {plansLoaded && (
        <>
          {customerType === CustomerType.PROXY_SELFSERVICE && (
            <>
              {status === UserStatus.CONFIRMED && !hasPlans && (
                <Banner
                  message='Please complete your checkout.'
                  link='/checkout'
                  linkTitle='Click here to continue'
                />
              )}
              {status === UserStatus.BANKED && !hasActivePlans && (
                <>
                  <Banner
                    message='Ready to connect? Choose your ideal plan now for immediate access to our premium proxy services.'
                    link='/plans'
                    linkTitle='Select a Plan'
                  />
                </>
              )}
              {status === UserStatus.BANKED &&
                hasActivePlans &&
                !additionalInfo && (
                  <Banner
                    message='Contact support@joinmassive.com if you have questions about our policies or want to complete KYC to gain access to content that is restricted by default.'
                    link='/kyc'
                    linkTitle='Submit KYC now'
                  />
                )}
            </>
          )}
          {customerType === CustomerType.PROXY_ENTERPRISE && (
            <>
              {!hasActivePlans && !pendingPlanId && (
                <>
                  <Banner
                    message='Please wait while we assign a plan for you. You can browse our documentation while awaiting access.'
                    link={getResidentialProxyApiDocsUrl()}
                    linkTitle='View Docs'
                  />
                </>
              )}
              {pendingPlanId && !stripeSessionId && (
                <Banner
                  message='You’ve been assigned a custom plan. Complete checkout to start using it now.'
                  link={`/checkout/${pendingPlanId}`}
                  linkTitle='Complete checkout'
                />
              )}
            </>
          )}
        </>
      )}

      {plansLoaded && <UserPlans plans={plans} customerType={customerType} />}
    </>
  )
}

export default YourPlan
