const UserStatus = Object.freeze({
  CREATED: 'created',
  CONFIRMED: 'confirmed',
  KYC_CONFIRMED: 'kyc_confirmed', // Deprecated since https://joinmassive.atlassian.net/browse/PROXY-215
  REJECTED: 'rejected',
  IGNORED: 'ignored',
  TRIAL: 'trial',
  BANKED: 'banked',
  PAUSED: 'paused'
})

export default UserStatus
