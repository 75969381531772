import './styles.scss'

import AuthLayout from 'components/Layouts/AuthLayout'
import { Link, useHistory } from 'react-router-dom'

import EmailForm from './components/EmailForm'
import PasswordForm from './components/PasswordForm'

const PasswordReset = ({ location }) => {
  const history = useHistory()
  const parameters = new URLSearchParams(location.search)

  return (
    <AuthLayout>
      <div className='reset-password-container'>
        {parameters.get('mode') === 'resetPassword' ? (
          <PasswordForm code={parameters.get('oobCode')} history={history} />
        ) : (
          <EmailForm />
        )}
        <div className='remembered-password'>
          <span>
            Remember your password<em>?</em> <Link to='/login'>Log in</Link>
          </span>
        </div>
      </div>
    </AuthLayout>
  )
}

export default PasswordReset
