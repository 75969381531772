import './styles.scss'

import { forwardRef, useEffect, useState } from 'react'
import Select from 'react-select'

const FormMultiSelect = forwardRef(
  ({ name, options, setValues, defaultValues, props }, ref) => {
    const [selectedOptions, setSelectedOptions] = useState(defaultValues)

    const handleChange = selectedValues => {
      setSelectedOptions(selectedValues)
      setValues(selectedValues)
    }

    useEffect(() => {
      setSelectedOptions(defaultValues)
    }, [defaultValues])

    return (
      <Select
        ref={ref}
        value={selectedOptions}
        isMulti
        name={name}
        options={options}
        placeholder={`Insert partner's email...`}
        className='basic-multi-select form-multi-select'
        classNamePrefix='select'
        onChange={handleChange}
        {...props}
      />
    )
  }
)

export default FormMultiSelect
