import './styles.scss'

import { useState } from 'react'

const Usage = ({ used, amount, minChargedBandwidth, maxBandwidth }) => {
  const [popupStyle, setPopupStyle] = useState({
    display: 'none',
    top: 0,
    right: 0
  })

  const handleMouseEnter = () => {
    if (maxBandwidth) return

    setPopupStyle({
      display: 'block',
      top: `64px`,
      right: `0px`
    })
  }

  const handleMouseLeave = () => {
    if (maxBandwidth) return

    setPopupStyle({ display: 'none' })
  }

  return (
    <div
      className='your-plan-card__usage'
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className='your-plan-card__label'>
        <span>Used</span>
        <div className='your-plan-card__usage-info'>
          <div className='your-plan-card__usage-text'>
            {used} {amount && `/ ${amount}`}
          </div>
        </div>
      </div>

      {minChargedBandwidth && !maxBandwidth && (
        <div className='your-plan-card__popup' style={popupStyle}>
          Minimum charged bandwidth: <span>{minChargedBandwidth}</span>
          <br />
          <br />
          <span>+</span> means unlimited usage with additional overage charges
        </div>
      )}
    </div>
  )
}

export default Usage
