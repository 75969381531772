import axios from 'axios'

const getUrl = route => {
  return `${process.env.REACT_APP_BACKEND_API_PARTNERS_URL}/${route}`
}

const makeGetRequest = async (route, params) => {
  try {
    const headers = {}

    headers['X-Api-Key'] = process.env.REACT_APP_BACKEND_API_PARTNERS_KEY

    return axios.get(getUrl(route), { params }, { headers })
  } catch (error) {
    throw new Error(error)
  }
}

const makePostRequest = async (route, data) => {
  try {
    const headers = {}

    headers['X-Api-Key'] = process.env.REACT_APP_BACKEND_API_PARTNERS_KEY

    const response = await axios.post(getUrl(route), data, { headers })

    return response
  } catch (error) {
    throw new Error(error)
  }
}

export { makeGetRequest, makePostRequest }
