import { completePasswordReset } from 'api/user/firebase'
import { useState } from 'react'
import { NotificationManager } from 'react-notifications'
import { useHistory } from 'react-router-dom'

const PasswordForm = ({ code }) => {
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const history = useHistory()

  const validateInput = () => {
    if (password !== confirmPassword) {
      NotificationManager.error(
        'Please re-enter the passwords.',
        'The passwords you entered don’t match.'
      )

      return false
    }

    return true
  }

  const handlePasswordReset = async event => {
    event.preventDefault()

    if (validateInput()) {
      document.body.style.cursor = 'wait'

      try {
        await completePasswordReset(code, password)
        NotificationManager.success('Your password was successfully reset.')
        history.push('/login')
      } catch (error) {
        const Sentry = await import('@sentry/react')

        Sentry.captureException(error)
        NotificationManager.error(
          error.message,
          'Something went wrong – please try again.'
        )
      }

      document.body.style.cursor = 'default'
    }
  }

  return (
    <form onSubmit={handlePasswordReset}>
      <input
        type='password'
        id='password-resetpassword'
        name='password'
        value={password}
        placeholder='New password'
        title='A letter, a number, and a total of 8 to 24 characters'
        autoComplete='new-password'
        pattern='(?=.*[A-Za-z])(?=.*\d).{8,24}'
        required
        onChange={event => setPassword(event.target.value)}
      />
      <input
        type='password'
        id='confirmpassword-resetpassword'
        name='confirmPassword'
        value={confirmPassword}
        placeholder='New password again'
        title='A letter, a number, and a total of 8 to 24 characters'
        autoComplete='new-password'
        pattern='(?=.*[A-Za-z])(?=.*\d).{8,24}'
        required
        onChange={event => setConfirmPassword(event.target.value)}
      />
      <button type='submit' className='btn-submit'>
        Reset password
      </button>
    </form>
  )
}

export default PasswordForm
