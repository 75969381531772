import './styles.scss'

import HCaptcha from '@hcaptcha/react-hcaptcha'
import ThemeContext from 'contexts/ThemeContext'
import { useContext } from 'react'

const Captcha = ({ setToken }) => {
  const themeData = useContext(ThemeContext)
  const { theme } = themeData

  return (
    <div className='captcha-container'>
      <HCaptcha
        sitekey={process.env.REACT_APP_HCAPTCHA_SITEKEY}
        onVerify={setToken}
        onError={() => setToken(null)}
        onExpire={() => setToken(null)}
        theme={theme === 'dark' ? 'dark' : 'light'}
      />
    </div>
  )
}

export default Captcha
