import { initiateEmailUpdate } from 'api/user/lambda'
import Button from 'components/Button'
import Input from 'components/Input'
import SectionTitle from 'components/SectionTitle'
import { useState } from 'react'
import { NotificationManager } from 'react-notifications'

const EmailUpdater = ({ uid, email }) => {
  const [newEmail, setNewEmail] = useState(email)

  const validateInput = (previousEmail, nextEmail) => {
    if (previousEmail === nextEmail) {
      NotificationManager.error(
        'Please enter a different email address.',
        'The address you entered is the same as your current address.'
      )

      return false
    }

    return true
  }

  const sendConfirmationEmail = async event => {
    event.preventDefault()

    document.body.style.cursor = 'wait'

    if (!validateInput(email, newEmail)) {
      document.body.style.cursor = 'default'

      return
    }

    try {
      await initiateEmailUpdate(uid, newEmail)
      NotificationManager.success(
        'Check your email to complete the address update.'
      )
    } catch (error) {
      NotificationManager.error(
        error.message,
        'Something went wrong – please try again.'
      )

      const Sentry = await import('@sentry/react')

      Sentry.captureException(error)
    }

    document.body.style.cursor = 'default'
  }

  return (
    <form onSubmit={sendConfirmationEmail}>
      <SectionTitle title='Portal Email' />
      <Input
        required
        type='email'
        name='email'
        placeholder={email}
        value={newEmail}
        onValueChange={nextEmail => setNewEmail(nextEmail.toLowerCase())}
        autoComplete='username'
      />
      <Button type='submit' label='Update' inline />
    </form>
  )
}

export default EmailUpdater
