import '../dark-mode.scss'
import '../styles.scss'
import './styles.scss'

import Select from 'react-select'

import { customStyles, customTheme } from '../styles'

const adminStyles = {
  ...customStyles,
  container: defaultStyle => ({
    ...defaultStyle,
    width: '128px',
    fontFamily: "'Montserrat', 'sans-serif'"
  })
}

const capitalizeFirstLetter = string => {
  if (!string) return ''

  return string.charAt(0).toUpperCase() + string.slice(1)
}

const AdminDropdown = ({ options, placeholder = null }) => (
  <Select
    className='react-select-container admin-dropdown'
    classNamePrefix='react-select'
    onChange={option => option.function()}
    options={options}
    isSearchable={false}
    hideSelectedOptions
    theme={customTheme}
    styles={adminStyles}
    placeholder={capitalizeFirstLetter(placeholder) || 'Actions'}
  />
)

export default AdminDropdown
