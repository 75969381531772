import { getAllPlans } from 'api/user/backend'
import { useEffect, useState } from 'react'

const fetchPlans = async (setPlans, setError, setIsLoading) => {
  try {
    setIsLoading(true)

    const plans = await getAllPlans(true)

    setPlans(plans)
  } catch (err) {
    setError(err)
  } finally {
    setIsLoading(false)
  }
}

const useUserAllPlans = () => {
  const [plans, setPlans] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)

  useEffect(() => {
    fetchPlans(setPlans, setError, setIsLoading).then()
  }, [])

  return { plans, isLoading, error }
}

export default useUserAllPlans
