import './styles.scss'

import clsx from 'clsx'

const SectionTitle = ({ title, className }) => {
  const classes = clsx('section-title', className)

  return <div className={classes}>{title}</div>
}

export default SectionTitle
