import './styles.scss'

import { CustomerType } from 'api/types'
import ApiTokenViewer from 'components/ApiTokenViewer'
import PartnerLayout from 'components/Layouts/PartnerLayout'
import ProxyCredentialsViewer from 'components/ProxyCredentialsViewer'
import AuthContext from 'contexts/AuthContext'
import useAccount from 'hooks/useAccount'
import { useContext } from 'react'

import EmailUpdater from './components/EmailUpdater'
import PasswordUpdater from './components/PasswordUpdater'

const Profile = () => {
  const { uid, email, walletId, customerType, isAdmin } =
    useContext(AuthContext)
  const { account, setAccount, isLoading, error } = useAccount()

  const renderCredentialsViewer = () => {
    if (isAdmin) return null

    if (customerType === CustomerType.SDK) {
      return <ApiTokenViewer value={walletId} />
    }

    return (
      <ProxyCredentialsViewer
        account={account}
        setAccount={setAccount}
        isLoading={isLoading}
        error={error}
      />
    )
  }

  return (
    <PartnerLayout>
      <div className='profile-container'>
        {renderCredentialsViewer()}
        <EmailUpdater uid={uid} email={email} />
        <PasswordUpdater email={email} />
      </div>
    </PartnerLayout>
  )
}

export default Profile
