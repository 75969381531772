import Tap from '@tapfiliate/tapfiliate-js'
import { useEffect } from 'react'

const useTapfiliate = (
  accountId = process.env.REACT_APP_TAPFILIATE_ACCOUNT_ID
) => {
  useEffect(() => {
    Tap.init(accountId, { integration: 'stripe' })

    // FIXME: Looks like there were multiple 'detects'. This might indicate you have multiple instances of our tracking code on this page
    // Tap.detect() // Automatically detect and track page views
  }, [accountId])
}

export default useTapfiliate
