import http from './http'

export const getUserAccount = async url => {
  const defaultMsg =
    'Not provisioned. Please contact support@joinmassive.com if you think this is an error.'

  try {
    const response = await http.get(url)
    const { proxy_username: username, proxy_api_key: apiKey } = response.data

    return {
      username: apiKey === '' ? defaultMsg : username || defaultMsg,
      apiKey: apiKey || defaultMsg
    }
  } catch (error) {
    return {
      username: defaultMsg,
      apiKey: defaultMsg
    }
  }
}

export const getUserUpcomingInvoice = async url => {
  try {
    const { data: invoice } = await http.get(url)

    if (
      !invoice ||
      (Array.isArray(invoice) && invoice.length === 0) ||
      (typeof invoice === 'object' && Object.keys(invoice).length === 0)
    ) {
      return null
    }

    return invoice
  } catch (error) {
    return null
  }
}
