import './styles.scss'

import Button from 'components/Button'
import LogoIcon from 'components/SVGs/LogoIcon'

const Message = ({ title, description, redirect = null }) => (
  <div className='message'>
    <LogoIcon className='message__icon' />
    <h3 className='message__title'>{title}</h3>
    <div
      className='message__description'
      // eslint-disable-next-line
      dangerouslySetInnerHTML={{ __html: description }}
    />
    {redirect && (
      <Button
        className='message__button'
        inline
        label={redirect.label}
        onClick={() => window.open(redirect.href, '_blank')}
      />
    )}
  </div>
)

export default Message
