import './styles.scss'

import { checkOtp } from 'api/user/backend'
import Button from 'components/Button'
import React, { useState } from 'react'

const FormCodeInput = React.forwardRef(
  (
    {
      onChange,
      value,
      verificationSid,
      setIsPhoneConfirmed,
      errorMessage,
      ...props
    },
    ref
  ) => {
    const [code, setCode] = useState('')
    const [confirmationStatus, setConfirmationStatus] = useState('')

    const handleCodeChange = event => {
      const enteredCode = event.target.value

      setCode(enteredCode)
      onChange(enteredCode)
    }

    const handleVerifyClick = async () => {
      setConfirmationStatus('Verifying... Please wait.')

      try {
        const success = await checkOtp(verificationSid, code)

        if (success) {
          setConfirmationStatus('✅ Phone number verified!')
          setIsPhoneConfirmed(true)
        } else {
          throw new Error('Verification failed.')
        }
      } catch (error) {
        setConfirmationStatus('Something went wrong. Please try again.')
      }
    }

    return (
      <>
        <div className='form-code-input'>
          <input
            ref={ref}
            className='form-code-input__input'
            {...props}
            value={code}
            onChange={handleCodeChange}
          />
          <Button
            type='button'
            className='button additional-info-form__button'
            label='Confirm'
            onClick={handleVerifyClick}
            inline
          />
          <span className='form-code-input__status-label'>
            {confirmationStatus}
          </span>
        </div>
        {errorMessage && <div className='form-item__error'>{errorMessage}</div>}
      </>
    )
  }
)

export default FormCodeInput
