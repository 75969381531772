import './styles.scss'

import Button from 'components/Button'
import { useState } from 'react'
import Modal from 'react-modal'
import { NotificationManager } from 'react-notifications'
import { useHistory } from 'react-router-dom'
import Select from 'react-select'

const customStyles = {
  menu: provided => ({
    ...provided,
    height: '210px' // Change the height of the dropdown list
  }),
  menuList: provided => ({
    ...provided,
    maxHeight: '210px', // Adjust this value to fit approximately 5 items
    overflowY: 'auto'
  })
}

const simplifiedPlans = plans =>
  plans.map(plan => ({
    value: plan.id,
    label: plan.name
  }))

const AssignPlanModal = ({ userId, plans, isOpen, onRequestClose }) => {
  const history = useHistory()

  const [selectedOption, setSelectedOption] = useState(null)

  const handleSelectChange = option => setSelectedOption(option)
  const handleOkClick = async () => {
    try {
      const { value: planId } = selectedOption

      onRequestClose()

      setSelectedOption(null)

      history.push(`/admin/plan/${planId}?assign_to=${userId}`)
    } catch (error) {
      const errMsg = error.response
        ? error.response.data.message
        : error.message

      NotificationManager.error(errMsg)
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      appElement={document.getElementById('root')}
      overlayClassName='assign-plan-modal-overlay'
      className='assign-plan-modal-content'
    >
      <h2>Assign Plan</h2>
      <Select
        value={selectedOption}
        onChange={handleSelectChange}
        options={simplifiedPlans(plans)}
        styles={customStyles}
      />
      <div className='assign-plan-modal-buttons'>
        <Button label='OK' inline onClick={handleOkClick} />
      </div>
    </Modal>
  )
}

export default AssignPlanModal
