const LogoIcon = ({ className, ariaHidden = true }) => (
  <svg
    width='70'
    height='28'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    aria-hidden={ariaHidden}
    className={className}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M1.227 22.143 20.24 2.745a9.13 9.13 0 0 1 12.965-.087l14.141 14.046c.132.13.345.13.475-.003a.338.338 0 0 0 .012-.46l-5.78-6.532a2.123 2.123 0 0 1-.112-2.673l2.625-3.525a4.93 4.93 0 0 1 7.677-.301l16.655 19.069a3.23 3.23 0 0 1-.295 4.543c-.585.515-1.336.8-2.114.8H3.51a3.212 3.212 0 0 1-3.205-3.22c0-.845.33-1.656.92-2.259Z'
      fill='#FF7A64'
    />
  </svg>
)

export default LogoIcon
