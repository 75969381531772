import './styles.scss'

import Button from 'components/Button'
import Modal from 'react-modal'

const TrialModal = ({
  isOpen,
  closeModal,
  trialSettings,
  setTrialSettings,
  handleApprove
}) => {
  const updateTrialSetting = (key, value) => {
    setTrialSettings(prevSettings => ({
      ...prevSettings,
      [key]: value
    }))
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      appElement={document.getElementById('root')}
      overlayClassName='modal-overlay'
      className='modal-content'
    >
      <h2>Configure Trial Settings for Enterprise Partner</h2>
      <form className='form-container'>
        <div>
          <label htmlFor='maxBandwidth'>
            <span>Max allowed bandwidth (GB):</span>
            <input
              id='maxBandwidth'
              type='number'
              value={trialSettings.maxBandwidth}
              onChange={e => updateTrialSetting('maxBandwidth', e.target.value)}
            />
          </label>
          <label htmlFor='expiresIn'>
            <span>Expires in (days):</span>
            <input
              id='expiresIn'
              type='number'
              value={trialSettings.expiresIn}
              onChange={e => updateTrialSetting('expiresIn', e.target.value)}
            />
          </label>
        </div>
        <div className='buttons'>
          <button type='button' onClick={closeModal}>
            Cancel
          </button>
          <Button
            type='button'
            onClick={handleApprove}
            label='Start Trial'
            inline
          />
        </div>
      </form>
    </Modal>
  )
}

export default TrialModal
