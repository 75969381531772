import BillingCycle, {
  billingCycleOptions,
  getDefaultBillingCycle
} from './BillingCycle'
import ChargeMode, {
  chargeModeOptions,
  chargeModeToAutoRenewTriggers,
  getDefaultChargeMode
} from './ChargeMode'
import CustomerType, { customerTypeToEmoji } from './CustomerType'
import PlanType from './PlanType'
import ProjectType, { projectTypeOptions } from './ProjectType'
import Rollover, { rolloverOptions } from './Rollover'
import UserStatus from './UserStatus'

const averageMonthlyUsageOptions = [
  '5 GB',
  '15 GB',
  '50 GB',
  '100 GB',
  '250 GB',
  '500 GB',
  '1 TB',
  'Greater than 1 TB'
].map(value => ({ value, label: value }))

export {
  averageMonthlyUsageOptions,
  BillingCycle,
  billingCycleOptions,
  ChargeMode,
  chargeModeOptions,
  chargeModeToAutoRenewTriggers,
  CustomerType,
  customerTypeToEmoji,
  getDefaultBillingCycle,
  getDefaultChargeMode,
  PlanType,
  ProjectType,
  projectTypeOptions,
  Rollover,
  rolloverOptions,
  UserStatus
}
