import './styles.scss'

import AuthContext from 'contexts/AuthContext'
import { useContext } from 'react'

import Sidebar from '../Sidebar'
import TopNav from '../TopNav'

const Header = () => {
  const { email } = useContext(AuthContext) || {}

  return email ? <Sidebar email={email} /> : <TopNav />
}

export default Header
