import './styles.scss'

import ArrowRightIcon from 'components/SVGs/ArrowRightIcon'

const PaginationButtons = ({ pagination, setPagination }) => {
  const handleNextPage = () => {
    setPagination(prev => ({
      ...prev,
      currentPage: prev.currentPage + 1
    }))
  }

  const handlePreviousPage = () => {
    setPagination(prev => ({
      ...prev,
      currentPage: Math.max(prev.currentPage - 1, 0)
    }))
  }

  return (
    <div className='pagination-buttons'>
      <button
        type='button'
        className='pagination-buttons__button pagination-buttons__button--prev'
        disabled={pagination.currentPage === 0}
        onClick={handlePreviousPage}
      >
        <ArrowRightIcon width={20} height={20} color='#00416A' />
      </button>

      <span className='pagination-buttons__page-number'>
        {pagination.currentPage + 1}
      </span>

      <button
        type='button'
        className='pagination-buttons__button'
        disabled={pagination.lastPage === pagination.currentPage}
        onClick={handleNextPage}
      >
        <ArrowRightIcon width={20} height={20} color='#00416A' />
      </button>
    </div>
  )
}

export default PaginationButtons
