import './styles.scss'

import clsx from 'clsx'
import Header from 'components/Navbar/Header'

const getChildrenWithContainer = (hideContainer, children, containerClasses) =>
  !hideContainer ? <div className={containerClasses}>{children}</div> : children

const PartnerLayout = ({
  children,
  hideContainer,
  containerClass,
  noBorder
}) => {
  const containerClasses = clsx('partner-layout__container', containerClass, [
    noBorder && 'no-border'
  ])

  const childrenWithContainer = getChildrenWithContainer(
    hideContainer,
    children,
    containerClasses
  )

  return (
    <div className='partner-layout'>
      <Header />
      <div className='partner-layout__content'>{childrenWithContainer}</div>
    </div>
  )
}

export default PartnerLayout
