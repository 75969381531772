import '../styles.scss'

import InvoiceList from 'containers/User/Invoices/InvoiceList'

const InvoicesTab = ({
  arePastInvoicesLoading,
  pastInvoices,
  upcomingInvoice
}) => {
  return (
    <div className='tab-content'>
      <InvoiceList
        {...{
          arePastInvoicesLoading,
          pastInvoices,
          upcomingInvoice
        }}
      />
    </div>
  )
}

export default InvoicesTab
