import { getUpcomingInvoice } from 'api/user/backend'
import { useEffect, useState } from 'react'

const fetchInvoices = async (setInvoice, setError, setIsLoading) => {
  try {
    setIsLoading(true)

    const upcomingInvoice = await getUpcomingInvoice()

    setInvoice(upcomingInvoice)
  } catch (err) {
    setError(err)
    throw new Error(err)
  } finally {
    setIsLoading(false)
  }
}

const useUpcomingInvoice = () => {
  const [invoice, setInvoice] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)

  useEffect(() => {
    fetchInvoices(setInvoice, setError, setIsLoading).then()
  }, [])

  return { invoice, isLoading, error }
}

export default useUpcomingInvoice
