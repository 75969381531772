const Rollover = {
  NO_ROLLOVER: 0,
  ONE_MONTH: 1,
  TWO_MONTHS: 2,
  THREE_MONTHS: 3
}

export const rolloverOptions = [
  { value: 0, label: 'No Rollover' },
  { value: 1, label: '1 Month' },
  { value: 2, label: '2 Months' },
  { value: 3, label: 'Quarterly' }
]

export default Rollover
