import './styles.scss'

import Button from 'components/Button'
import Modal from 'react-modal'

const ConfirmModal = ({ isOpen, onRequestClose, onConfirm }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      appElement={document.getElementById('root')}
      overlayClassName='modal-overlay'
      className='modal-content'
    >
      <h2>Are you sure you want to disable auto-renew?</h2>
      <p>
        To enable auto-renewal again, please purchase a new plan with auto-renew
        enabled.
      </p>
      <div style={{ float: 'right' }}>
        <Button
          className='your-plan-card__autorenew-button'
          label='Cancel'
          onClick={onRequestClose}
          style={{
            width: '100px'
          }}
          inline
        />
        <Button
          label='Yes'
          onClick={() => {
            onRequestClose()
            onConfirm()
          }}
          style={{
            width: '100px'
          }}
          inline
        />
      </div>
    </Modal>
  )
}

export default ConfirmModal
