import './styles.scss'

import React from 'react'

const FormTextArea = React.forwardRef(({ errorMessage, ...props }, ref) => (
  <div style={{ width: '100%' }}>
    <textarea ref={ref} className='form-textarea' {...props} />
    {errorMessage && <div className='form-item__error'>{errorMessage}</div>}
  </div>
))

export default FormTextArea
