import './styles.scss'

import MobileMenu from 'components/MobileMenu'
import Logo from 'components/SVGs/Logo'
import { NavLink } from 'react-router-dom'
import { getBaseDocsUrl } from 'services/Mintlify'

const navigation = [
  { name: 'Plans', href: '/plans' },
  { name: 'Documentation', href: getBaseDocsUrl(), isExternal: true },
  {
    name: 'Help',
    href: 'https://share.hsforms.com/1rnKibRlcRmq46ECyQOpJIgq2gce',
    isExternal: true
  }
]

const renderNavLink = ({ name, href, isExternal = false }) => {
  if (isExternal) {
    return (
      <a
        className='nav-link'
        key={name}
        href={href}
        target='_blank'
        rel='noopener noreferrer'
      >
        {name}
      </a>
    )
  }

  return (
    <NavLink
      key={name}
      className='nav-link'
      to={href}
      activeClassName='nav-link--active'
    >
      {name}
    </NavLink>
  )
}

const TopNav = () => (
  <>
    <nav className='top-nav'>
      <div className='top-nav__container'>
        <NavLink to='/'>
          <Logo />
        </NavLink>
        <div className='top-nav__nav'>{navigation.map(renderNavLink)}</div>
        <NavLink
          className='nav-button'
          to='/login'
          activeClassName='nav-button--active'
        >
          Login
        </NavLink>
      </div>
    </nav>

    <MobileMenu />
  </>
)

export default TopNav
