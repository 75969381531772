export const customTheme = theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: '#ff9964',
    primary25: '#eff5fc',
    neutral20: '#d4e5f9',
    neutral30: '#d4e5f9',
    neutral40: '#ff9964',
    neutral60: '#ff9964',
    neutral80: '#ff9964'
  }
})

export const customStyles = {
  container: (defaultStyle, state) => ({
    ...defaultStyle,
    width: state.selectProps.width,
    fontFamily: "'Montserrat', 'sans-serif'",
    marginTop: 18,
    marginBottom: 18
  }),
  option: defaultStyle => ({
    ...defaultStyle,
    cursor: 'pointer',
    fontSize: 14
  }),
  control: defaultStyle => ({
    ...defaultStyle,
    minHeight: 40,
    cursor: 'pointer',
    fontSize: 14
  }),
  singleValue: defaultStyle => ({
    ...defaultStyle,
    color: '#000000'
  }),
  dropdownIndicator: defaultStyle => ({
    ...defaultStyle,
    color: '#ff9964'
  }),
  menu: defaultStyle => ({
    ...defaultStyle,
    marginTop: 2,
    marginBottom: 0
  }),
  menuList: defaultStyle => ({
    ...defaultStyle,
    paddingTop: 0,
    paddingBottom: 0
  })
}
