import { confirmPasswordReset } from 'api/user/lambda'
import Button from 'components/Button'
import Captcha from 'components/Captcha'
import { useState } from 'react'
import { NotificationManager } from 'react-notifications'
import { useHistory } from 'react-router-dom'

const EmailForm = () => {
  const [email, setEmail] = useState('')
  const [token, setToken] = useState(null)
  const [alreadySent, setAlreadySent] = useState(false)
  const history = useHistory()

  const handlePasswordReset = async event => {
    event.preventDefault()

    if (!alreadySent) {
      if (token) {
        document.body.style.cursor = 'wait'

        try {
          await confirmPasswordReset(email, token)
          setAlreadySent(true)
          NotificationManager.success(
            'Check your email to continue the password reset.'
          )
          history.push('/login')
        } catch (error) {
          const Sentry = await import('@sentry/react')

          Sentry.captureException(error)
          NotificationManager.error(
            error.message,
            'Something went wrong – please try again.'
          )
        }

        document.body.style.cursor = 'default'
      } else {
        NotificationManager.error('You must verify that you’re human.')
      }
    }
  }

  return (
    <form onSubmit={handlePasswordReset}>
      <input
        type='text'
        id='email-resetpassword'
        name='email'
        value={email}
        placeholder='Your email'
        required
        onChange={event => setEmail(event.target.value)}
      />
      <Captcha setToken={setToken} />
      <Button
        type='submit'
        label='Reset Password'
        className='submit-button'
        inline
      />
    </form>
  )
}

export default EmailForm
