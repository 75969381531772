import { getPlan } from 'api/admin/backend'
import { useEffect, useState } from 'react'

const fetchPlan = async (planId, setPlan, setError, setIsLoading) => {
  try {
    setIsLoading(true)

    const plan = await getPlan(planId)

    setPlan(plan)
  } catch (err) {
    setError(err)
  } finally {
    setIsLoading(false)
  }
}

const usePlan = ({ planId }) => {
  const [plan, setPlan] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)

  useEffect(() => {
    if (!planId) {
      return
    }

    fetchPlan(planId, setPlan, setError, setIsLoading).then()
  }, [planId])

  return { plan, isLoading, error }
}

export default usePlan
