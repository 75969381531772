export const getBaseDocsUrl = () => `${process.env.REACT_APP_MASSIVE_DOCS_URL}`

export const getResidentialProxyApiDocsUrl = () =>
  `${getBaseDocsUrl()}/residential-proxy-reference/introduction`

export const getProxyResellerApiDocsUrl = () =>
  `${getBaseDocsUrl()}/reseller-api-reference/reseller-api-introduction`

export const getSdkDocsUrl = () => `${getBaseDocsUrl()}/sdk`

export const getSdkDesktopDocsUrl = () => `${getSdkDocsUrl()}/desktop`

export const getSdkMobileDocsUrl = () =>
  `${getSdkDocsUrl()}/mobile-and-smart-tv`

export const getSdkAdvancedFeaturesDocsUrl = () =>
  `${getBaseDocsUrl()}/advanced-features`

export const getSearchApiDocsUrl = () =>
  `${getSdkAdvancedFeaturesDocsUrl()}/serp-api`

export const getReportingApiDocsUrl = () =>
  `${getSdkAdvancedFeaturesDocsUrl()}/reporting-api`
