import Tap from '@tapfiliate/tapfiliate-js'
import { getTrxData } from 'api/user/backend'

export const trackConversion = async sessionId => {
  try {
    if (!sessionId) {
      return
    }

    const { chargeId, amount, customerId } = await getTrxData(sessionId)

    Tap.conversion(
      chargeId,
      parseFloat(amount),
      {
        customer_id: customerId,
        always_callback: true
      },
      null,
      error => {
        if (error) {
          // eslint-disable-next-line no-console
          console.error('Error tracking conversion:', error)
        }
      }
    )
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
  }
}

export default trackConversion
