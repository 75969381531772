import * as Sentry from '@sentry/react'

const initSentry = () => {
  const isProd = process.env.NODE_ENV === 'production'

  if (isProd) {
    Sentry.init({
      dsn: 'https://24b572cac079412ab435f7cd9cd21481@o428832.ingest.sentry.io/5405231'
    })
  }
}

export default initSentry
