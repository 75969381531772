import { login, updateUserPassword } from 'api/user/firebase'
import Button from 'components/Button'
import Input from 'components/Input'
import SectionTitle from 'components/SectionTitle'
import { useState } from 'react'
import { NotificationManager } from 'react-notifications'

const PasswordUpdater = ({ email }) => {
  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmedPassword, setConfirmedPassword] = useState('')

  const handleUpdatingPassword = async event => {
    event.preventDefault()

    document.body.style.cursor = 'wait'

    try {
      await login(email, currentPassword)

      if (newPassword !== confirmedPassword) {
        NotificationManager.error('The new passwords don’t match.')

        return
      }

      await updateUserPassword(confirmedPassword)

      NotificationManager.success('Your password was successfully updated.')
    } catch (error) {
      NotificationManager.error(
        error.message,
        'Something went wrong – please try again.'
      )

      const Sentry = await import('@sentry/react')

      Sentry.captureException(error)
    }

    document.body.style.cursor = 'default'
  }

  return (
    <form onSubmit={handleUpdatingPassword}>
      <SectionTitle title='Portal Password' />
      <Input
        required
        type='password'
        name='current-password'
        placeholder='Current password'
        onValueChange={password => setCurrentPassword(password)}
        autoComplete='current-password'
      />
      <Input
        required
        shouldValidatePassword
        type='password'
        name='new-password'
        placeholder='New password'
        onValueChange={password => setNewPassword(password)}
        autoComplete='new-password'
      />
      <Input
        required
        shouldValidatePassword
        type='password'
        name='confirm-password'
        placeholder='New password again'
        onValueChange={password => setConfirmedPassword(password)}
        autoComplete='confirm-password'
      />
      <Button type='submit' label='Update' inline />
    </form>
  )
}

export default PasswordUpdater
