import './styles.scss'

import clsx from 'clsx'

const FormSection = ({ className, title, children, ...props }) => {
  const classes = clsx('form-section', [className])

  return (
    <>
      {title && <h3 className='form-section--title'>{title}</h3>}
      <section className={classes} {...props}>
        {children}
      </section>
    </>
  )
}

export default FormSection
