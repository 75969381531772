const BillingCycle = Object.freeze({
  NONE: 0,
  ONE_MONTH: 1,
  THREE_MONTHS: 3,
  SIX_MONTHS: 6,
  TWELVE_MONTHS: 12
})

export const billingCycleOptions = [
  { value: BillingCycle.NONE, label: 'No Expiration' },
  { value: BillingCycle.ONE_MONTH, label: '1 Month' },
  { value: BillingCycle.THREE_MONTHS, label: 'Quarterly' },
  { value: BillingCycle.SIX_MONTHS, label: 'Bi-Annual' },
  { value: BillingCycle.TWELVE_MONTHS, label: 'Annual' }
]

export const getDefaultBillingCycle = () => BillingCycle.ONE_MONTH

export default BillingCycle
