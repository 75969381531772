import './styles.scss'

import { forwardRef } from 'react'
import { NumericFormat } from 'react-number-format'

const FormNumericInput = forwardRef(
  (
    { onValueChange, onChange, className = 'form-numeric-input', ...rest },
    ref
  ) => (
    <NumericFormat
      getInputRef={ref}
      className={className}
      onValueChange={(values, sourceInfo) => {
        if (onValueChange) {
          onValueChange(values, sourceInfo)
        }
        if (onChange) {
          onChange({
            target: {
              name: rest.name,
              value: values.floatValue
            }
          })
        }
      }}
      thousandSeparator
      valueIsNumericString
      autoComplete='off'
      {...rest}
    />
  )
)

export default FormNumericInput
