import './styles.scss'

import FormNumericInput from 'components/common/Form/FormNumericInput'
import { forwardRef, useState } from 'react'

const FormDynamicList = forwardRef(
  ({ defaultValues, setValues, isPreview }, ref) => {
    const [items, setItems] = useState(defaultValues)

    const updateValues = updatedItems => {
      setItems(updatedItems)
      setValues(updatedItems)
    }

    const handleAddMore = () => {
      const updatedItems = [...items, { price_per_unit: '', up_to_unit: '' }]

      updateValues(updatedItems)
    }

    const handleRemove = index => {
      const newItems = items.filter((_, i) => i !== index)

      updateValues(newItems)
    }

    const handleChange = (index, name, value) => {
      const newItems = items.map((item, i) =>
        i === index ? { ...item, [name]: +value } : item
      )

      updateValues(newItems)
    }

    return (
      <div className='container'>
        <div className='item-form'>
          {items.map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div className='item-form__row' key={index}>
              <FormNumericInput
                ref={ref}
                value={item.price_per_unit}
                placeholder='$ per GB'
                onValueChange={values => {
                  handleChange(index, 'price_per_unit', values.floatValue)
                }}
                disabled={isPreview}
                prefix='$'
              />
              <FormNumericInput
                value={item.up_to_unit || ''}
                placeholder={isPreview ? '∞' : 'Amount of GB'}
                onValueChange={values => {
                  handleChange(index, 'up_to_unit', values.floatValue)
                }}
                disabled={isPreview}
                suffix=' GB'
              />
              {!isPreview && index !== 0 && (
                <button
                  type='button'
                  className='item-form__remove-btn'
                  onClick={() => handleRemove(index)}
                >
                  Remove
                </button>
              )}
            </div>
          ))}
          {!isPreview && (
            <button
              type='button'
              className='item-form__add-more-btn'
              onClick={handleAddMore}
            >
              Add More
            </button>
          )}
        </div>
      </div>
    )
  }
)

export default FormDynamicList
