import { v4 as uuidv4 } from 'uuid'

import { getUserAccount, getUserUpcomingInvoice } from '../common'
import http from '../http'

// Provisions a new trial each time it is called, in other words
// creates a new trial instead of updating an existing one.
export const provisionNewEnterpriseTrial = async (uid, settings) => {
  const body = {
    allowed_gbs: +settings.maxBandwidth,
    expire_in_days: +settings.expiresIn
  }

  return http.post(`/admin/account/${uid}/assign-trial`, body)
}

export const getAllUserPlans = async uid => {
  const { status, data: plans } = await http.get(
    `/admin/account/${uid}/all-plans`
  )

  return status === 200 && plans && plans.length > 0 ? plans : []
}

export const getActivePlans = async uid => {
  const plans = await getAllUserPlans(uid)

  return plans.filter(plan => plan.active)
}

export const getPastInvoices = async uid => {
  const { data: invoices } = await http.get(`/admin/account/${uid}/invoices`)

  return invoices || []
}

export const getUpcomingInvoice = async userId =>
  getUserUpcomingInvoice(`/admin/account/${userId}/upcoming-invoice`)

export const getAccount = async userId =>
  getUserAccount(`/admin/account/${userId}`)

export const toggleProxyAccess = async (uid, shouldPause) => {
  const { status } = await http.post(
    `/admin/account/${uid}/${shouldPause ? 'pause' : 'resume'}-proxy-access`
  )

  return status === 200
}

export const createNewPlan = async data => {
  const planId = uuidv4()

  const { status } = await http.post(`/admin/plan/${planId}`, data)

  return {
    success: status === 201,
    planId
  }
}

export const updatePlan = async (planId, data) => {
  const { status } = await http.patch(`/admin/plan/${planId}`, data)

  return status === 200
}

export const assignPlan = async (userId, planId) => {
  const { status } = await http.post(
    `/admin/account/${userId}/assign-plan/${planId}`,
    {}
  )

  return status === 200
}

export const getPlan = async planId => {
  const { data } = await http.get(`/admin/plan/${planId}`)

  return data
}

export const getAllPlans = async () => {
  const { data } = await http.get('/admin/plans')

  return data
}
