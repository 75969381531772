const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    margin: '0.5rem 0'
  },
  label: {
    marginLeft: '8px',
    fontSize: '16px'
  }
}

const Toggle = ({ isToggled, onToggle, label = 'Auto-renew' }) => {
  const handleKeyDown = event => {
    if (event.key === 'Enter' || event.key === ' ') {
      onToggle()
    }
  }

  return (
    <div
      role='button'
      tabIndex={0}
      style={styles.container}
      onClick={onToggle}
      onKeyDown={handleKeyDown}
    >
      {isToggled ? (
        <svg
          width='32'
          height='16'
          viewBox='0 0 32 16'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <rect
            x='0.5'
            y='0.5'
            width='31'
            height='15'
            rx='7.5'
            fill='#FFE3D3'
          />
          <rect
            x='0.5'
            y='0.5'
            width='31'
            height='15'
            rx='7.5'
            stroke='#FFE3D3'
          />
          <rect
            x='17.6'
            y='1.59961'
            width='12.8'
            height='12.8'
            rx='6.4'
            fill='#FF9D65'
          />
        </svg>
      ) : (
        <svg
          width='32'
          height='16'
          viewBox='0 0 32 16'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <rect
            x='0.5'
            y='0.5'
            width='31'
            height='15'
            rx='7.5'
            fill='#D4E5F9'
          />
          <rect
            x='0.5'
            y='0.5'
            width='31'
            height='15'
            rx='7.5'
            stroke='#D4E5F9'
          />
          <rect
            x='1.6001'
            y='1.59961'
            width='12.8'
            height='12.8'
            rx='6.4'
            fill='white'
          />
        </svg>
      )}
      <div style={styles.label}>{label}</div>
    </div>
  )
}

export default Toggle
