import './styles.scss'

const Spinner = () => (
  <div className='spinner'>
    <div className='spinner__circle' />
    <div className='spinner__center' />
  </div>
)

export default Spinner
