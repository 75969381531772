import './styles.scss'

import {
  billingCycleOptions,
  ChargeMode,
  chargeModeOptions,
  getDefaultChargeMode,
  rolloverOptions
} from 'api/types'
import Button from 'components/Button'
import Copyright from 'components/common/Copyright'
import Form, {
  FormDynamicList,
  FormInput,
  FormItem,
  FormMultiSelect,
  FormNumericInput,
  FormSelect,
  FormTextArea,
  FormTitle
} from 'components/common/Form'
import PlanSummary from 'containers/User/UserPlans/PlanSummary'
import { useEffect } from 'react'
import { Controller, useWatch } from 'react-hook-form'

import FormField from '../FormField'

const RULES = { required: 'This field is required!' }

const PlanForm = ({
  handleSubmit,
  control,
  isSubmitting,
  setValue,
  resetField,
  defaultTiers,
  enterprisePartners,
  defaultEnterprisePartners,
  isPreviewMode,
  plan
}) => {
  const chargeMode = useWatch({
    control,
    name: FormField.CHARGE_MODE,
    defaultValue: getDefaultChargeMode()
  })

  const isAutoPayment = chargeMode === ChargeMode.AUTO
  const isManualPayment = chargeMode === ChargeMode.MANUAL

  useEffect(() => {
    if (isManualPayment) {
      resetField(FormField.MAX_GB)
    }
  }, [resetField, isManualPayment])

  return (
    <Form onSubmit={handleSubmit} className='create-plan-form'>
      <FormTitle title={`${isPreviewMode ? 'Preview' : 'Create'} Plan`} />

      <FormItem
        label='Name'
        hint='Viewable by admin and customers.'
        htmlFor={FormField.NAME}
        required
      >
        <Controller
          rules={RULES}
          control={control}
          name={FormField.NAME}
          render={({ field }) => (
            <FormInput
              type='text'
              placeholder='Custom Enterprise Plan'
              {...field}
            />
          )}
        />
      </FormItem>

      <FormItem label='Description' htmlFor={FormField.DESC}>
        <Controller
          control={control}
          name={FormField.DESC}
          render={({ field }) => (
            <FormTextArea
              placeholder='Add details about the plan for admin purposes...'
              rows={4}
              {...field}
            />
          )}
        />
      </FormItem>

      <FormItem
        label='Billing Cycle Length'
        hint='For example, 1 means a client gets billed monthly, whereas 3 means they get billed quarterly.'
        htmlFor={FormField.BILLING_CYCLE}
        required
      >
        <Controller
          rules={RULES}
          control={control}
          name={FormField.BILLING_CYCLE}
          render={({ field }) => (
            <FormSelect
              options={billingCycleOptions}
              onChange={event => {
                setValue(FormField.BILLING_CYCLE, +event.target.value)
              }}
              disabled={isPreviewMode}
              {...field}
            />
          )}
        />
      </FormItem>

      <FormItem label='Charge Mode' htmlFor={FormField.CHARGE_MODE} required>
        <Controller
          control={control}
          name={FormField.CHARGE_MODE}
          render={({ field }) => (
            <FormSelect
              options={chargeModeOptions}
              onChange={event => {
                setValue(FormField.CHARGE_MODE, event.target.value)
              }}
              disabled={isPreviewMode}
              {...field}
            />
          )}
        />
      </FormItem>

      <FormItem
        label='Minimum GB to be Charged'
        htmlFor={FormField.MIN_GB_TO_BE_CHARGED}
      >
        <Controller
          control={control}
          name={FormField.MIN_GB_TO_BE_CHARGED}
          render={({ field }) => (
            <FormNumericInput
              disabled={isPreviewMode}
              suffix=' GB'
              {...field}
            />
          )}
        />
      </FormItem>

      {isAutoPayment && (
        <FormItem
          label='Maximum GB per Billing Cycle'
          hint="For prepaid plans, set the maximum GB to match the minimum GB. If there's no limit, leave it blank."
          htmlFor={FormField.MAX_GB}
        >
          <Controller
            control={control}
            name={FormField.MAX_GB}
            render={({ field }) => (
              <FormNumericInput
                disabled={isPreviewMode}
                suffix=' GB'
                {...field}
              />
            )}
          />
        </FormItem>
      )}

      <FormItem
        label='Rollover'
        hint='Sets the amount of time allowed for rollover credits to be added when clients underutilize GB in a given billing period.'
        htmlFor={FormField.ROLLOVER}
      >
        <Controller
          control={control}
          name={FormField.ROLLOVER}
          render={({ field }) => (
            <FormSelect
              options={rolloverOptions}
              onChange={event => {
                setValue(FormField.ROLLOVER, +event.target.value)
              }}
              disabled={isPreviewMode}
              {...field}
            />
          )}
        />
      </FormItem>

      <FormItem
        label='Tiers'
        hint='The first field represents $ per GB. The second field represents the maximum GB for which that pricing pertains. Leave the second field bank if there is uniform pricing for all usage.'
        htmlFor={FormField.TIERS}
        style={{ justifyContent: 'space-around' }}
        required
      >
        <Controller
          rules={isPreviewMode ? {} : RULES}
          control={control}
          name={FormField.TIERS}
          render={({ field }) => (
            <FormDynamicList
              defaultValues={defaultTiers}
              setValues={items => setValue(FormField.TIERS, items)}
              isPreview={isPreviewMode}
              {...field}
            />
          )}
        />
      </FormItem>

      <FormItem
        label='Allowed for'
        hint={`Choose the enterprise partner(s) ${
          isManualPayment
            ? 'to whom this plan can be assigned.'
            : 'who can pick this plan at checkout.'
        }`}
        htmlFor={FormField.ASSIGN_TO}
        required
      >
        <Controller
          rules={isPreviewMode ? {} : RULES}
          control={control}
          name={FormField.ASSIGN_TO}
          render={({ field }) => (
            <FormMultiSelect
              name='emails'
              options={enterprisePartners}
              defaultValues={defaultEnterprisePartners}
              setValues={items => setValue(FormField.ASSIGN_TO, items)}
              {...field}
            />
          )}
        />
      </FormItem>

      {isPreviewMode && (
        <PlanSummary
          key={plan.id}
          plan={plan}
          customerType='admin'
          isPreview={isPreviewMode}
        />
      )}

      <Button
        className='create-plan-form__button'
        type='submit'
        label={
          isSubmitting ? 'Processing...' : isPreviewMode ? 'Update' : 'Create'
        }
        disabled={isSubmitting}
      />

      <Copyright />
    </Form>
  )
}

export default PlanForm
