import '../styles.scss'

import Button from 'components/Button'
import Banner from 'components/common/Banner'
import UserPlans from 'containers/User/UserPlans'
import useAllPlans from 'hooks/useAllPlans'
import { useState } from 'react'

import AssignPlanModal from './AssignPlanModal'

const PlansTab = ({ userId, userPlans, pendingPlanId }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false)

  const { plans } = useAllPlans()

  const openModal = () => setModalIsOpen(true)
  const closeModal = () => setModalIsOpen(false)

  return (
    <div className='tab-content'>
      <div className='plans-controls'>
        <Button label='Assign Plan' onClick={openModal} inline />
      </div>

      {pendingPlanId && (
        <Banner
          message='A plan has already been assigned to the user. Awaiting checkout completion.'
          link={`/admin/plan/${pendingPlanId}`}
          linkTitle='View plan'
        />
      )}

      <UserPlans plans={userPlans} />

      <AssignPlanModal
        userId={userId}
        plans={plans}
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
      />
    </div>
  )
}

export default PlansTab
