import './styles.scss'

import clsx from 'clsx'

const Form = ({ layout, className, ...props }) => {
  const isVerticalLayout = layout === 'vertical'
  const classes = clsx('form', className, [
    isVerticalLayout && 'form--vertical'
  ])

  return <form className={classes} {...props} />
}

export default Form
