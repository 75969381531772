import './styles.scss'

import { PlanType } from 'api/types'
import { disableAutoRenew } from 'api/user/backend'
import clsx from 'clsx'
import Button from 'components/Button'
import Panel from 'components/common/Panel'
import { useState } from 'react'
import { NotificationManager } from 'react-notifications'
import { Link } from 'react-router-dom'
import { getPlanParams, pluralize } from 'utils/plans'

import ConfirmModal from './ConfirmModal'
import Usage from './Usage'

const getPlanTitle = planName =>
  planName && planName.toLowerCase().includes('plan')
    ? planName
    : `${planName} Plan`

const PlanSummary = ({ plan, customerType = undefined, isPreview = false }) => {
  const { plan_id: selectedPlanId, name, auto_renew: autoRenew, active } = plan

  const [shouldAutoRenew, setAutoRenew] = useState(autoRenew)
  const [modalIsOpen, setModalIsOpen] = useState(false)

  const {
    planType,
    expirationDate,
    expirationDays,
    price,
    priceText,
    minChargedBandwidth,
    maxBandwidth,
    used,
    amount
  } = getPlanParams(plan)

  const closeModal = () => {
    setModalIsOpen(false)
  }

  const toggleAutoRenew = async () => {
    try {
      if (shouldAutoRenew) {
        setAutoRenew(false)

        const success = await disableAutoRenew()

        if (success) {
          NotificationManager.success(
            'The auto-renewal feature is disabled now.'
          )
        }
      }
    } catch (error) {
      setAutoRenew(true)

      NotificationManager.error(
        'Unable to disable auto-renewal. Please contact support@joinmassive.com for help.'
      )
    }
  }

  const renderPriceLabels = priceLabels => {
    if (priceLabels.length === 0 || priceLabels.includes('0.00')) return null

    return priceLabels.map((tierLabel, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <div className='your-plan-card__price-per-gb' key={index}>
        <span className='your-plan-card__price-text'>{tierLabel}</span>
      </div>
    ))
  }

  const planCardClass = clsx('your-plan-card', {
    'your-plan-card--active': active === true,
    'your-plan-card--inactive': active === false,
    'your-plan-card--preview': active == null // handles both undefined and null
  })

  return (
    <Panel className={planCardClass}>
      <div className='your-plan-card__container'>
        <div className='your-plan-card__header'>
          <div className='your-plan-card__info'>
            <div className='your-plan-card__title'>{getPlanTitle(name)}</div>
            <div className='your-plan-card__expiration'>
              {expirationDays < 0 &&
                `Expired ${pluralize(Math.abs(expirationDays), 'day')} ago`}
              {!expirationDays && `Expire${active ? 's' : 'd'} today`}
              {expirationDays > 0 &&
                `${pluralize(expirationDays, 'day')} to expire`}
            </div>
          </div>
          <div className='your-plan-card__details'>
            {planType === PlanType.TRIAL ? (
              <div className='your-plan-card__price-trial'>FREE TRIAL</div>
            ) : (
              <div className='your-plan-card__price'>
                {price ? `${price}` : ''}
              </div>
            )}
            <Usage
              used={used}
              amount={amount}
              minChargedBandwidth={minChargedBandwidth}
              maxBandwidth={maxBandwidth}
            />
          </div>
        </div>
        <div className='your-plan-card__actions'>
          <div className='your-plan-card__features'>
            <div className='your-plan-card__feature'>
              <span className='your-plan-card__feature-text'>
                {amount || 'Unlimited GB'}
              </span>
            </div>
            {renderPriceLabels(priceText)}
            {shouldAutoRenew && (
              <div className='your-plan-card__feature'>
                <span className='your-plan-card__feature-text'>
                  Auto-renew enabled
                </span>
              </div>
            )}
          </div>
          {planType === PlanType.PREPAID &&
            customerType !== 'admin' &&
            !shouldAutoRenew && (
              <div className='your-plan-card__manage'>
                <Link
                  className='your-plan-card__manage-plan-link'
                  to={{
                    pathname: '/plans',
                    state: { selectedPlanId }
                  }}
                >
                  Purchase Plan
                </Link>
              </div>
            )}
        </div>
        <div className='your-plan-card__header'>
          {planType === PlanType.TRIAL ? (
            <div className='your-plan-card__autorenew'>
              This is a free trial plan.
            </div>
          ) : (
            <div className='your-plan-card__info'>
              {(shouldAutoRenew || isPreview) && (
                <div className='your-plan-card__title'>
                  <Button
                    className='your-plan-card__autorenew-button'
                    label='Disable Auto-Renewal'
                    onClick={() => setModalIsOpen(true)}
                    inline
                  />
                </div>
              )}
              <div className='your-plan-card__autorenew'>
                {shouldAutoRenew || isPreview ? (
                  <>
                    {planType === PlanType.PAYG &&
                      `You will be billed for your usage on ${expirationDate}.`}
                    {planType === PlanType.PREPAID &&
                      `The plan will renew on ${expirationDate} or when data runs out.`}
                    {planType === PlanType.CUSTOM &&
                      `Your plan will renew on ${expirationDate}.`}
                  </>
                ) : (
                  <strong>
                    The plan will not renew. To enable auto-renewal again,
                    please purchase a new plan with auto-renew enabled.
                  </strong>
                )}
              </div>
            </div>
          )}
        </div>
        <div className='your-plan-card__autorenew'>
          Please contact support at support@joinmassive.com for any plan
          inquiries.
        </div>
      </div>
      <ConfirmModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        onConfirm={toggleAutoRenew}
      />
    </Panel>
  )
}

export default PlanSummary
