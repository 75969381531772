const BurgerMenu = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 26 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect y='0.5' width='26' height='3' rx='1.5' fill='black' />
    <rect y='7.5' width='26' height='3' rx='1.5' fill='black' />
    <rect y='14.5' width='26' height='3' rx='1.5' fill='black' />
  </svg>
)

export default BurgerMenu
