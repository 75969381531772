import './styles.scss'

import { login } from 'api/user/firebase'
import Button from 'components/Button'
import AuthLayout from 'components/Layouts/AuthLayout'
import { useEffect, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import { Link, useLocation } from 'react-router-dom'
import { getMsgFromException } from 'utils/firebase'

function getNotificationText(status) {
  switch (status) {
    case 'email-updated':
      return {
        title: 'Email updated!',
        description:
          'Your email has been updated. Please log in with the new email address to apply the changes.'
      }
    default:
      return {
        title: '',
        description: ''
      }
  }
}

const Login = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const { search } = useLocation()

  useEffect(() => {
    // This effect will notify users when their email is updated successfully
    // if the status query parameter is present in the URL.

    const searchParams = new URLSearchParams(search)
    const status = searchParams.get('status')

    if (status === 'email-updated') {
      const { title, description } = getNotificationText(status)

      NotificationManager.success(description, title)
    }
  }, [search])

  const handleLogin = async event => {
    event.preventDefault()

    document.body.style.cursor = 'wait'

    try {
      await login(email, password)
      window.location.reload()
    } catch (error) {
      const Sentry = await import('@sentry/react')

      Sentry.captureException(error)
      NotificationManager.error(
        getMsgFromException(error),
        'Login failed – please try again.'
      )
    }

    document.body.style.cursor = 'default'
  }

  return (
    <AuthLayout>
      <div className='login-container'>
        <form onSubmit={handleLogin}>
          <input
            type='email'
            id='email-login'
            name='email'
            value={email}
            placeholder='Your email'
            autoComplete='username'
            required
            onChange={event => setEmail(event.target.value)}
          />
          <input
            type='password'
            id='password-login'
            name='password'
            value={password}
            placeholder='Password'
            autoComplete='current-password'
            required
            onChange={event => setPassword(event.target.value)}
          />
          <Button type='submit' className='submit-button' label='Log In' />
        </form>
        <div className='register'>
          <span>
            Don’t have an account<em>?</em>{' '}
            <Link to='/create-account'>Register</Link>
          </span>
        </div>
        <div className='reset-password'>
          <Link to='/reset'>Reset your password</Link>
        </div>
      </div>
    </AuthLayout>
  )
}

export default Login
