import { CustomerType } from 'api/types'
import DashboardIcon from 'components/SVGs/DashboardIcon'
import DocIcon from 'components/SVGs/DocIcon'
import HelpIcon from 'components/SVGs/HelpIcon'
import PlanIcon from 'components/SVGs/PlanIcon'
import ProfileIcon from 'components/SVGs/ProfileIcon'
import {
  getBaseDocsUrl,
  getResidentialProxyApiDocsUrl,
  getSdkDocsUrl
} from 'services/Mintlify'

export const navigationWithoutLogin = [
  { name: 'Plans', href: '/plans', icon: PlanIcon },
  {
    name: 'Documentation',
    href: getBaseDocsUrl(),
    icon: DocIcon,
    isExternal: true
  },
  {
    name: 'Help',
    href: 'https://form.typeform.com/to/J3Q7cIJx',
    icon: HelpIcon,
    isExternal: true
  }
]

export const adminNavigation = [
  { name: 'Partners', href: '/admin/dashboard', icon: DashboardIcon },
  { name: 'Plans', href: '/admin/plans', icon: PlanIcon },
  {
    name: 'Documentation',
    href: getBaseDocsUrl(),
    icon: DocIcon,
    isExternal: true
  }
]

export const sdkNavigation = [
  {
    name: 'Dashboard',
    href: '/dashboard',
    icon: DashboardIcon
  },
  {
    name: 'Documentation',
    href: getSdkDocsUrl(),
    icon: DocIcon,
    isExternal: true
  },
  { name: 'Profile', href: '/profile', icon: ProfileIcon },
  {
    name: 'Help',
    href: 'https://share.hsforms.com/1rnKibRlcRmq46ECyQOpJIgq2gce',
    icon: HelpIcon,
    isExternal: true
  }
]

export const proxyNavigation = [
  { name: 'Dashboard', href: '/dashboard', icon: PlanIcon },
  {
    name: 'Documentation',
    href: getResidentialProxyApiDocsUrl(),
    icon: DocIcon,
    isExternal: true
  },
  { name: 'Profile', href: '/profile', icon: ProfileIcon },
  { name: 'Invoices', href: '/invoices', icon: PlanIcon },
  {
    name: 'Help',
    href: 'https://joinmassive.atlassian.net/servicedesk/customer/portal/2/group/2/create/10',
    icon: HelpIcon,
    isExternal: true
  }
]

export const getNavigation = (email, isAdmin, customerType) => {
  if (!email) {
    return navigationWithoutLogin
  }

  if (isAdmin) {
    return adminNavigation
  }

  if (customerType === CustomerType.SDK) {
    return sdkNavigation
  }

  if (
    customerType === CustomerType.PROXY_SELFSERVICE ||
    customerType === CustomerType.PROXY_ENTERPRISE
  ) {
    return proxyNavigation
  }

  return []
}

export default getNavigation
