import './styles.scss'

import Banner from 'components/common/Banner'
import SectionTitle from 'components/SectionTitle'
import Toggle from 'components/Toggle'
import { useState } from 'react'

import PlanSummary from './PlanSummary'

// Returns active plans or all plans based on `showAllPlans` flag
const filterPlans = (plans, showAllPlans) => {
  return showAllPlans ? plans : plans?.filter(plan => plan.active && plan.tiers)
}

const makeSectionTitle = (customerType, isShowingAllPlans, planCount) => {
  return `${customerType === 'admin' ? "User's" : 'Your'} ${
    isShowingAllPlans ? '' : 'Active'
  } Plan${planCount > 1 ? 's' : ''}`
}

const UserPlans = ({ plans, customerType = 'admin' }) => {
  const [showAllPlans, setShowAllPlans] = useState(false)

  const hasArchivedPlans = plans?.some(plan => !plan.active)

  const filteredPlans = filterPlans(plans, showAllPlans)

  const sectionTitle = makeSectionTitle(
    customerType,
    showAllPlans,
    filteredPlans?.length
  )

  return (
    <>
      <div className='section-header'>
        <SectionTitle title={sectionTitle} />
        {hasArchivedPlans && (
          <Toggle
            label='Show all plans'
            isToggled={showAllPlans}
            onToggle={() => setShowAllPlans(prev => !prev)}
          />
        )}
      </div>

      {!filteredPlans?.length ? (
        <Banner message='There are no active plans.' />
      ) : (
        filteredPlans
          .sort((a, b) => {
            return new Date(b.created_at) - new Date(a.created_at)
          })
          .map(plan => (
            <PlanSummary
              key={plan.id}
              plan={plan}
              customerType={customerType}
            />
          ))
      )}
    </>
  )
}

export default UserPlans
