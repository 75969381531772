import './styles.scss'

import React from 'react'

const renderSelectOption = (option, index) => (
  <option key={index} value={option.value} selected={option.selected}>
    {option.label}
  </option>
)

const FormSelect = React.forwardRef((props, ref) => {
  const { options, onChange, ...rest } = props

  return (
    <select ref={ref} className='form-select' onChange={onChange} {...rest}>
      {options.map(renderSelectOption)}
    </select>
  )
})

export default FormSelect
