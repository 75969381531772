import './styles.scss'

import { resetApiKey } from 'api/user/backend'
import Button from 'components/Button'
import Input from 'components/Input'
import SectionTitle from 'components/SectionTitle'
import { useState } from 'react'
import { NotificationManager } from 'react-notifications'

const copyToClipboard = (key, value) => {
  navigator.clipboard.writeText(value)
  NotificationManager.info(`The proxy ${key} was copied to your clipboard.`)
}

const ProxyCredentials = ({ account, setAccount, isLoading, error }) => {
  const [showValue, setShowValue] = useState(false)

  const { username, apiKey } = account

  const toggleVisibility = () => {
    setShowValue(!showValue)
  }

  const resetPassword = async () => {
    try {
      setAccount({ username: 'Loading...', apiKey: 'Updating...' })

      const newAccount = await resetApiKey()

      setAccount(newAccount)

      NotificationManager.success('Proxy Password reset')

      setShowValue(false)
    } catch (err) {
      NotificationManager.error(err.message, 'Error resetting proxy password')
    }
  }

  if (error) {
    NotificationManager.error(
      error.message,
      'Error while fetching proxy account.'
    )

    return null
  }

  return (
    <>
      <SectionTitle title='Proxy Password' />
      <div className='proxy-credential'>
        <Input
          type={showValue ? 'text' : 'password'}
          name='proxy-password'
          value={apiKey}
          disabled
          autoComplete='api-key'
        />
        <Button
          type='button'
          label={showValue ? 'Hide' : 'Show'}
          onClick={toggleVisibility}
          disabled={isLoading}
          inline
        />
        <Button
          type='button'
          disabled={isLoading}
          label='Copy'
          onClick={() => copyToClipboard('password', apiKey)}
          inline
        />
        <Button
          type='button'
          disabled={isLoading}
          label='Reset'
          onClick={resetPassword}
          inline
        />
      </div>
      <SectionTitle title='Proxy Username' />
      <div>
        <Input
          type='text'
          name='proxy-username'
          value={username}
          disabled
          autoComplete='api-key'
        />
        <Button
          type='button'
          disabled={isLoading}
          label='Copy'
          onClick={() => copyToClipboard('username', username)}
          inline
        />
      </div>
    </>
  )
}

export default ProxyCredentials
