import './styles.scss'

import Copyright from 'components/common/Copyright'
import LogoTitle from 'components/LogoTitle'
import Switch from 'components/Theme/Switch'

const AuthLayout = ({ hasHeader, children }) => (
  <>
    <div className='auth-container'>
      <div className='auth'>
        {hasHeader || (
          <>
            {false && <Switch />}
            <LogoTitle />
          </>
        )}
        {children}
        <Copyright />
      </div>
    </div>
  </>
)

export default AuthLayout
