import { getAccount } from 'api/user/backend'
import { useEffect, useState } from 'react'

const fetchAccount = async (setAccount, setError, setIsLoading) => {
  try {
    setIsLoading(true)

    const account = await getAccount()

    setAccount(account)
  } catch (err) {
    setError(err)
  } finally {
    setIsLoading(false)
  }
}

const useAccount = () => {
  const [account, setAccount] = useState({
    username: 'Loading...',
    apiKey: 'Loading...'
  })
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    fetchAccount(setAccount, setError, setIsLoading).then()
  }, [])

  return { account, setAccount, isLoading, error }
}

export default useAccount
