import '../styles.scss'

const MoreInfoTab = ({ user }) => {
  const { additionalInfo = {}, url } = user

  const {
    address = {},
    phoneNumber = '',
    companyWebsite = '',
    linkedinURL = '',
    typeOfProject = '',
    useCase = ''
  } = additionalInfo

  const {
    address1 = '',
    address2 = '',
    city = '',
    state = '',
    country = '',
    postalCode = ''
  } = address

  const companyWebsiteURL = companyWebsite || url

  return (
    <div className='tab-content'>
      <p>
        <strong>Phone Number:</strong> {phoneNumber}
      </p>
      <p>
        <strong>Address Line 1:</strong> {address1}
      </p>
      <p>
        <strong>Apt/Suite:</strong> {address2}
      </p>
      <p>
        <strong>City:</strong> {city}
      </p>
      <p>
        <strong>State/Region:</strong> {state}
      </p>
      <p>
        <strong>Postal Code:</strong> {postalCode}
      </p>
      <p>
        <strong>Country:</strong> {country.toUpperCase()}
      </p>
      <p>
        <strong>Account Type:</strong> {typeOfProject}
      </p>
      <p>
        <strong>LinkedIn URL:</strong>{' '}
        <a href={linkedinURL} target='_blank' rel='noopener noreferrer'>
          {linkedinURL}
        </a>
      </p>
      {companyWebsiteURL && (
        <p>
          <strong>Company URL:</strong>{' '}
          <a href={companyWebsiteURL} target='_blank' rel='noopener noreferrer'>
            {companyWebsiteURL}
          </a>
        </p>
      )}
      <p>
        <strong>Use Case:</strong> {useCase}
      </p>
    </div>
  )
}

export default MoreInfoTab
