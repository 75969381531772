import './styles.scss'

import Panel from 'components/common/Panel'
import arrowDownIcon from 'containers/Documentation/images/arrow-down.svg'
import { formatCurrency } from 'utils/currency'
import { formatChartDate, formatReportDate } from 'utils/dates'

const InvoiceCard = ({ invoice = {}, isUpcoming = false }) => {
  const {
    paid_at: paidAt,
    period_start: periodStart,
    pdf_link: pdfLink,
    automatic_payment_at: autoPaymentAt, // For upcoming invoice, auto payment
    due_at: dueAt // For upcoming invoice, send invoice
  } = invoice

  const billingDate = isUpcoming ? autoPaymentAt || dueAt : paidAt
  const totalAmount = formatCurrency(invoice?.amount, 2, 2)

  return (
    <Panel className='invoice-card'>
      <div className='invoice-card__dates-section'>
        <div className='invoice-card__text-block'>
          <span className='invoice-card__label'>Billing Date:</span>
          <span className='invoice-card__value'>
            {formatChartDate(billingDate)}
          </span>
        </div>
        {!isUpcoming && (
          <>
            <div className='invoice-card__text-block'>
              <span className='invoice-card__label'>Period:</span>
              <span className='invoice-card__value'>
                {formatReportDate(periodStart)}
              </span>
            </div>
            {pdfLink && (
              <div className='invoice-card__text-block'>
                <span className='invoice-card__label'>Download PDF:</span>
                <span className='invoice-card__value'>
                  <a href={pdfLink} download>
                    <img
                      className='invoice-card__download-icon'
                      src={arrowDownIcon}
                      alt='Download PDF'
                    />
                  </a>
                </span>
              </div>
            )}
          </>
        )}
      </div>

      <div className='invoice-card__amount-section'>
        <div className='invoice-card__label'>Total Amount:</div>
        <div className='invoice-card__amount-value'>{totalAmount} USD</div>
      </div>
    </Panel>
  )
}

export default InvoiceCard
