import { makeGetRequest, makePostRequest } from 'utils/serverless'

// Initiates updating your email when you click the 'Update' button on the Profile page.
export const initiateEmailUpdate = async (uid, newEmail) => {
  return makePostRequest('confirmNewEmail', {
    params: {
      uid,
      newEmail
    }
  })
}

// Completes updating your email when you click the link in the email sent to you.
export const completeEmailUpdate = async params => {
  const response = await makeGetRequest('updateEmail', params)

  return response.status === 200 && response.data.status === 'updated'
}

// Prepares and sends an email with a link to confirm registration.
export const initiateRegistrationConfirmation = async (uid, token) => {
  return makePostRequest('confirmRegistration', {
    params: {
      uid,
      token
    }
  })
}

// Confirms registration when you click the link in the email sent to you.
export const confirmRegistration = async uid => {
  const params = {
    uid
  }

  return makeGetRequest('updateStatus', params)
}

export const confirmKyc = async uid => {
  const params = {
    uid
  }

  return makeGetRequest('updateStatusKycConfirmed', params)
}

// Resets the password on the Login page when you click the 'Reset your password' button.
// Note: This function sends an email with a link to https://computewall-dev.firebaseapp.com... on stage.
export const confirmPasswordReset = async (email, token) => {
  return makePostRequest('confirmPasswordReset', {
    params: {
      email,
      token
    }
  })
}
