const ProjectType = {
  COMPANY: 'company',
  PERSONAL: 'personal'
}

export const projectTypeOptions = [
  {
    value: ProjectType.COMPANY,
    label: 'Company'
  },
  {
    value: ProjectType.PERSONAL,
    label: 'Personal'
  }
]

export default ProjectType
