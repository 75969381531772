import { getPastInvoices } from 'api/user/backend'
import { useEffect, useState } from 'react'

const fetchInvoices = async (setInvoices, setError, setIsLoading) => {
  try {
    setIsLoading(true)

    const invoices = await getPastInvoices()

    setInvoices(invoices)
  } catch (err) {
    setError(err)
    throw new Error(err)
  } finally {
    setIsLoading(false)
  }
}

const usePastInvoices = () => {
  const [invoices, setInvoices] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)

  useEffect(() => {
    fetchInvoices(setInvoices, setError, setIsLoading).then()
  }, [])

  return { invoices, isLoading, error }
}

export default usePastInvoices
