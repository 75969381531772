import './styles.scss'

import BurgerMenu from 'components/SVGs/BurgerMenu'
import CloseIcon from 'components/SVGs/CloseIcon'
import Logo from 'components/SVGs/Logo'
import { useState } from 'react'
import { NavLink } from 'react-router-dom'

import ModalView from './ModalView'

const MobileMenu = () => {
  const [isShow, setIsShow] = useState(false)

  const changeVisible = () => {
    const el = document.getElementsByClassName('partner-layout__content')

    if (isShow === false) {
      el?.[0].classList.add('modal-open')
    }
    if (isShow === true) {
      el?.[0].classList.remove('modal-open')
    }
    setIsShow(!isShow)
  }

  return (
    <div className='mobile-menu__wrapper'>
      <div className='mobile-menu'>
        <div className='mobile-menu__logo'>
          <NavLink to='/'>
            <Logo />
          </NavLink>
        </div>
        <button
          type='button'
          className='mobile-menu__button'
          onClick={changeVisible}
        >
          {isShow ? (
            <CloseIcon width={21} height={22} />
          ) : (
            <BurgerMenu width={26} height={17} />
          )}
        </button>
      </div>
      {isShow === true ? <ModalView setIsShow={setIsShow} /> : null}
    </div>
  )
}

export default MobileMenu
