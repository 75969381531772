const ChargeMode = Object.freeze({
  AUTO: 'auto',
  MANUAL: 'send_invoice'
})

export const chargeModeOptions = [
  { value: ChargeMode.AUTO, label: 'Automatic Payment' },
  { value: ChargeMode.MANUAL, label: 'Send Invoice for Manual Payment' }
]

export const chargeModeToAutoRenewTriggers = (
  billingCycle,
  chargeMode,
  maxAllowedGbs
) => {
  if (!billingCycle) return []

  if (chargeMode === ChargeMode.AUTO) {
    const triggers = ['invoice_paid']

    if (maxAllowedGbs) {
      triggers.push('limit_reached')
    }

    return triggers
  }

  if (chargeMode === ChargeMode.MANUAL) {
    return ['expiration']
  }

  return []
}

export const getDefaultChargeMode = () => ChargeMode.AUTO

export default ChargeMode
