import './styles.scss'

import Button from 'components/Button'
import PartnerLayout from 'components/Layouts/PartnerLayout'
import useAllPlans from 'hooks/useAllPlans'
import { useHistory } from 'react-router-dom'
import { formatTableDate } from 'utils/dates'

const PlanManager = () => {
  const { plans, isLoading, error } = useAllPlans()
  const history = useHistory()

  const handleRowClick = id => {
    if (window.getSelection().toString()) {
      return
    }

    history.push(`/admin/plan/${id}`)
  }

  const getStatusText = () => {
    if (isLoading) {
      return '⏳ Syncing plans...'
    }

    if (error) {
      return '❌ Failed to sync plans.'
    }

    return '✅ Plans are up-to-date!'
  }

  return (
    <PartnerLayout>
      <div className='custom-plans-container'>
        <div className='custom-plans-controls'>
          <Button
            type='button'
            label='Create Custom Plan'
            onClick={() => history.push('/admin/plan')}
            inline
          />
          <span className='custom-plans-status'>{getStatusText()}</span>
        </div>
        <table className='plan-table'>
          <thead>
            <tr>
              <th className='plan-table__header-cell'>Created At</th>
              <th className='plan-table__header-cell'>ID</th>
              <th className='plan-table__header-cell'>Name</th>
            </tr>
          </thead>
          <tbody>
            {plans.map(({ created_at: createdAt, id, name }) => (
              <tr
                className='plan-table__row'
                key={id}
                onClick={() => handleRowClick(id)}
              >
                <td className='plan-table__cell'>
                  {formatTableDate(createdAt)}
                </td>
                <td className='plan-table__cell'>{id}</td>
                <td className='plan-table__cell'>{name}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </PartnerLayout>
  )
}

export default PlanManager
