import './styles.scss'

import clsx from 'clsx'

const Panel = ({ className, ...props }) => {
  const classes = clsx('panel', className)

  return <section className={classes} {...props} />
}

export default Panel
