import './styles.scss'

import { logout } from 'api/user/firebase'
import MobileMenu from 'components/MobileMenu'
import Logo from 'components/SVGs/Logo'
import LogoutIcon from 'components/SVGs/LogoutIcon'
import AuthContext from 'contexts/AuthContext'
import getNavigationItems from 'navigation'
import { useCallback, useContext } from 'react'
import { NavLink } from 'react-router-dom'

const renderNavLink = ({ name, href, icon: Icon, isExternal = false }) => {
  if (isExternal) {
    return (
      <a
        key={name}
        className='nav-link'
        href={href}
        target='_blank'
        rel='noopener noreferrer'
      >
        <Icon className='nav-link__icon' />
        {name}
      </a>
    )
  }

  return (
    <NavLink
      key={name}
      className='nav-link'
      to={href}
      activeClassName='nav-link--active'
    >
      <Icon className='nav-link__icon' />
      {name}
    </NavLink>
  )
}

const Sidebar = ({ email }) => {
  const { isAdmin, customerType } = useContext(AuthContext)

  const handleLogout = useCallback(async event => {
    event.preventDefault()
    await logout()
    window.location.href = '/login'
  }, [])

  return (
    <>
      <div className='side-bar__wrapper'>
        <div className='side-bar'>
          <div className='side-bar__logo'>
            <NavLink to='/'>
              <Logo />
            </NavLink>
          </div>

          <nav className='side-bar__nav'>
            {getNavigationItems(email, isAdmin, customerType).map(
              renderNavLink
            )}
          </nav>

          <div>
            <span className='side-bar__email'>{email}</span>
            <a className='nav-link' href='/' onClick={handleLogout}>
              <LogoutIcon className='nav-link__icon' />
              Log out
            </a>
          </div>
        </div>
      </div>
      <MobileMenu />
    </>
  )
}

export default Sidebar
