import './styles.scss'

import { assignPlan, createNewPlan, updatePlan } from 'api/admin/backend'
import { fetchBankedProxyEnterprisePartners } from 'api/admin/firebase'
import { sendCheckoutDetails } from 'api/admin/lambda'
import {
  ChargeMode,
  chargeModeToAutoRenewTriggers,
  getDefaultBillingCycle,
  getDefaultChargeMode,
  Rollover
} from 'api/types'
import Panel from 'components/common/Panel'
import PartnerLayout from 'components/Layouts/PartnerLayout'
import usePlan from 'hooks/usePlan'
import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { NotificationManager } from 'react-notifications'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import FormField from './FormField'
import PlanForm from './PlanForm'

const toNumOrNull = value => {
  return +value || null
}

const toNumOrEmptyStr = value => {
  return value ? value.toString() : ''
}

const arraysContainSameElements = (arr1, arr2) => {
  if (arr1.length !== arr2.length) return false

  const sortedArr1 = [...arr1].sort()
  const sortedArr2 = [...arr2].sort()

  return sortedArr1.every((value, index) => value === sortedArr2[index])
}

const isEmptyObject = obj => {
  return Object.entries(obj).length === 0
}

const CreatePlanForm = () => {
  const history = useHistory()
  const { id: planId } = useParams()
  const { search } = useLocation()
  const queryParams = new URLSearchParams(search)
  const userId = queryParams.get('assign_to')

  const [enterprisePartners, setEnterprisePartners] = useState([])
  const [defaultEnterprisePartners, setDefaultEnterprisePartners] = useState([])
  const [defaultTiers, setDefaultTiers] = useState([
    { price_per_unit: '', up_to_unit: '' }
  ])
  const { plan, isLoading } = usePlan({ planId })

  const isPreviewMode = planId && plan
  const isAssigningMode = isPreviewMode && userId

  const {
    control,
    formState: { isSubmitting },
    resetField,
    reset,
    handleSubmit,
    setValue
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      [FormField.NAME]: '',
      [FormField.DESC]: '',
      [FormField.BILLING_CYCLE]: getDefaultBillingCycle(),
      [FormField.CHARGE_MODE]: getDefaultChargeMode(),
      [FormField.MIN_GB_TO_BE_CHARGED]: '',
      [FormField.MAX_GB]: '',
      [FormField.ROLLOVER]: Rollover.NO_ROLLOVER,
      [FormField.TIERS]: [],
      [FormField.ASSIGN_TO]: []
    }
  })

  useEffect(() => {
    const fetchPartners = async () => {
      try {
        const partners = await fetchBankedProxyEnterprisePartners()

        setEnterprisePartners(partners)

        if (plan) {
          const assignedIds = [...(plan.assigned_to || [])]

          if (isAssigningMode && !assignedIds.includes(userId)) {
            assignedIds.push(userId)
          }

          const assignedPartners = assignedIds
            .map(id => partners.find(partner => partner.value === id))
            .filter(Boolean)

          setDefaultEnterprisePartners(assignedPartners)
          setValue(FormField.ASSIGN_TO, assignedPartners)
        }
      } catch (error) {
        NotificationManager.error(error.message)
      }
    }

    fetchPartners()
  }, [plan, isAssigningMode, userId, setValue])

  useEffect(() => {
    if (plan) {
      reset({
        [FormField.NAME]: plan.name,
        [FormField.DESC]: plan.description,
        [FormField.BILLING_CYCLE]: plan.details.billing_cycle_months,
        [FormField.CHARGE_MODE]: plan.details.charge_mode,
        [FormField.MIN_GB_TO_BE_CHARGED]: toNumOrEmptyStr(
          plan.details.min_charged_units
        ),
        [FormField.MAX_GB]: toNumOrEmptyStr(plan.details.allowed_units),
        [FormField.ROLLOVER]: plan.details.roll_over_cycles,
        [FormField.TIERS]: plan.details.tiers
      })

      setDefaultTiers(plan.details.tiers)
    }
  }, [userId, plan, reset])

  const createNewPlanOrUpdateExisting = useCallback(
    async data => {
      try {
        data[FormField.BILLING_CYCLE] = toNumOrNull(
          data[FormField.BILLING_CYCLE]
        )

        const minChargedGbs = toNumOrNull(data[FormField.MIN_GB_TO_BE_CHARGED])
        const maxAllowedGbs = toNumOrNull(data[FormField.MAX_GB])

        if (minChargedGbs && maxAllowedGbs && minChargedGbs > maxAllowedGbs) {
          throw new Error(
            'The minimum charged GBs must be less than the allowed GBs'
          )
        }

        data[FormField.MIN_GB_TO_BE_CHARGED] = minChargedGbs
        data[FormField.MAX_GB] = maxAllowedGbs

        data[FormField.AUTORENEW] = chargeModeToAutoRenewTriggers(
          data[FormField.BILLING_CYCLE],
          data[FormField.CHARGE_MODE],
          maxAllowedGbs
        )

        data[FormField.ROLLOVER] = toNumOrNull(data[FormField.ROLLOVER])

        data[FormField.TIERS] = data[FormField.TIERS]
          .filter(tier => tier.price_per_unit !== '' && tier.price_per_unit > 0)
          .map(tier => ({
            ...tier,
            up_to_unit: toNumOrNull(tier.up_to_unit)
          }))

        data[FormField.ASSIGN_TO] = data[FormField.ASSIGN_TO].map(
          ({ value }) => value
        )

        if (isPreviewMode) {
          const patch = {}

          if (data[FormField.NAME] !== plan.name) {
            patch.name = data[FormField.NAME]
          }

          if (data[FormField.DESC] !== plan.description) {
            patch.description = data[FormField.DESC]
          }

          if (
            !arraysContainSameElements(
              data[FormField.ASSIGN_TO],
              plan.assigned_to || []
            )
          ) {
            patch.assigned_to = data[FormField.ASSIGN_TO]
          }

          if (!isEmptyObject(patch)) {
            const success = await updatePlan(planId, patch)

            if (success) {
              if (!isAssigningMode) {
                NotificationManager.success(
                  `'${data[FormField.NAME]}' updated!`
                )
              }
            } else {
              NotificationManager.error('Failed to update the plan')
            }
          }

          if (
            isAssigningMode &&
            data[FormField.CHARGE_MODE] === ChargeMode.AUTO
          ) {
            sendCheckoutDetails(planId, [userId])

            NotificationManager.success(
              'Done! The user notified about plan checkout details'
            )

            history.push(`/profile/${userId}`)
          }

          if (
            isAssigningMode &&
            data[FormField.CHARGE_MODE] === ChargeMode.MANUAL
          ) {
            const success = await assignPlan(userId, planId)

            if (success) {
              NotificationManager.success('Plan assigned successfully!')

              history.push(`/profile/${userId}`)
            }
          }
        } else {
          const { success, planId: newPlanId } = await createNewPlan(data)

          if (success) {
            NotificationManager.success(`'${data[FormField.NAME]}' created!`)

            if (data[FormField.CHARGE_MODE] === ChargeMode.AUTO) {
              sendCheckoutDetails(newPlanId, data[FormField.ASSIGN_TO])
            }
          }

          history.push('/admin/plans')
        }
      } catch (error) {
        const errMsg = error.response
          ? error.response.data.message
          : error.message

        NotificationManager.error(errMsg)
      }
    },
    [isPreviewMode, isAssigningMode, plan, userId, planId, history]
  )

  if (planId && isLoading) {
    return null
  }

  return (
    <PartnerLayout hideContainer>
      <Panel className='create-plan'>
        <PlanForm
          handleSubmit={handleSubmit(createNewPlanOrUpdateExisting)}
          control={control}
          isSubmitting={isSubmitting}
          setValue={setValue}
          resetField={resetField}
          defaultTiers={defaultTiers}
          enterprisePartners={enterprisePartners}
          defaultEnterprisePartners={defaultEnterprisePartners}
          isPreviewMode={isPreviewMode}
          plan={plan}
        />
      </Panel>
    </PartnerLayout>
  )
}

export default CreatePlanForm
