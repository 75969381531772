import {
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
  updatePassword
} from 'firebase/auth'
import { getDatabase, onValue, ref, set, update } from 'firebase/database'
import firebaseApp, { filterObjectEntries } from 'utils/firebase'

import { initiateRegistrationConfirmation } from './lambda'

const auth = getAuth(firebaseApp)
const db = getDatabase(firebaseApp)

export const getCurrentToken = async () => {
  const user = auth.currentUser

  if (user) {
    try {
      const token = await user.getIdToken()

      return token
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Authentication token error:', error)
    }
  }

  return null
}

export const isLoggedIn = () =>
  new Promise(resolve => {
    onAuthStateChanged(auth, user => {
      if (user) {
        resolve(user.uid)
      } else {
        resolve(null)
      }
    })
  })

export const getUserProfile = uid =>
  new Promise((resolve, reject) => {
    try {
      const partnersRef = ref(db, `partners/${uid}`)

      onValue(partnersRef, snapshot => {
        const data = snapshot.val()

        resolve(data)
      })
    } catch (error) {
      reject(error)
    }
  })

export const login = async (email, password) => {
  return signInWithEmailAndPassword(auth, email, password)
}

export const logout = async () => {
  await signOut(auth)
}

export const signUp = async (email, password) => {
  const { user } = await createUserWithEmailAndPassword(auth, email, password)

  return user
}

export const createUserProfile = async (
  {
    uid,
    name,
    email,
    appDomain,
    firstName,
    lastName,
    planId,
    autoRenew,
    customerType
  },
  token
) => {
  const partnersRef = ref(db, `partners/${uid}`)

  const userProfileObject = {
    name,
    email,
    url: appDomain,
    firstName,
    lastName,
    planId,
    autoRenew,
    customerType,
    status: 'created',
    utcTimestamp: new Date().toISOString()
  }
  const response = await set(
    partnersRef,
    filterObjectEntries(userProfileObject)
  )

  await initiateRegistrationConfirmation(uid, token)

  return response
}

export const updateAdditionalInfo = async (uid, data) => {
  const partnersRef = ref(db, `partners/${uid}/additionalInfo`)

  const addressData = {
    ...data.address,
    address1: data.address.address1 || '',
    address2: data.address.address2 || '',
    city: data.address.city || '',
    state: data.address.state || '',
    postalCode: data.address.postalCode || ''
  }

  const updatedData = {
    ...data,
    address: addressData
  }

  await set(partnersRef, filterObjectEntries(updatedData))
}

export const completePasswordReset = async (code, newPassword) => {
  await confirmPasswordReset(auth, code, newPassword)
}

export const updateUserPassword = async newPassword => {
  const user = auth.currentUser

  await updatePassword(user, newPassword)
}

const updateField = async (userId, key, value = null) => {
  const partnersRef = ref(db, `partners/${userId}`)

  await update(partnersRef, {
    [key]: value
  })
}

/*
 * 'pendingPlanId' indicates a pending checkout.
 */
export const removePendingPlanId = async userId => {
  await updateField(userId, 'pendingPlanId')
}
