const ArrowRightIcon = ({ width, height, color = '#00416A' }) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 8 8'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d='m2.592 7.092 2.817-3.095-2.817-2.905' stroke={color} />
  </svg>
)

export default ArrowRightIcon
