import './styles.scss'

import Panel from 'components/common/Panel'
import { Link } from 'react-router-dom'

const Banner = ({ message, link, linkTitle }) => {
  const isExternalLink = link && link.startsWith('https')

  return (
    <Panel className='banner'>
      <div className='banner__container'>
        <div className='banner__message'>{message}</div>
        {link && linkTitle && (
          <div className='banner__link'>
            <div className='banner__link--button'>
              {isExternalLink ? (
                <a
                  className='banner__link--text'
                  href={link}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {linkTitle}
                </a>
              ) : (
                <Link className='banner__link--text' to={link}>
                  {linkTitle}
                </Link>
              )}
            </div>
          </div>
        )}
      </div>
    </Panel>
  )
}

export default Banner
