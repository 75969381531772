/* eslint-disable no-alert */
import {
  provisionNewEnterpriseTrial,
  toggleProxyAccess
} from 'api/admin/backend'
import {
  approvePartner,
  approveTrialPartner,
  ignorePartner,
  pausePartner,
  rejectPartner
} from 'api/admin/firebase'
import { CustomerType, UserStatus } from 'api/types'
import TrialModal from 'components/Actions/TrialModal'
import AdminDropdown from 'components/Dropdowns/AdminDropdown'
import { useState } from 'react'
import { NotificationManager } from 'react-notifications'

const makeOpt = (label, callback) => ({
  label,
  value: label.toLowerCase(),
  function: callback
})

const Actions = ({ partner, refreshPartners }) => {
  const { uid, customerType, status } = partner
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [trialSettings, setTrialSettings] = useState({
    maxBandwidth: 0,
    expiresIn: 0
  })

  const openModal = () => setModalIsOpen(true)
  const closeModal = () => setModalIsOpen(false)

  const confirmApprobation = async () => {
    try {
      await approvePartner(uid)
      refreshPartners()
      NotificationManager.success('Partner approved successfully.')
    } catch (error) {
      NotificationManager.error(error.message)
    }
  }

  const handleTrial = async () => {
    document.body.style.cursor = 'wait'
    closeModal()
    try {
      await approveTrialPartner(uid)
      await provisionNewEnterpriseTrial(uid, trialSettings)
      refreshPartners()
      NotificationManager.success(
        'Proxy Enterprise customer successfully approved for trial.'
      )
    } catch (error) {
      NotificationManager.error(error.message)
    } finally {
      document.body.style.cursor = 'default'
    }
  }

  const confirmProxyEnterpriseApprobation = async () => {
    openModal()
  }

  const confirmRejection = async () => {
    try {
      await rejectPartner(uid)
      refreshPartners()
      NotificationManager.success('Partner rejected successfully.')
    } catch (error) {
      NotificationManager.error(error.message)
    }
  }

  const confirmIgnoration = async () => {
    try {
      await ignorePartner(uid)
      refreshPartners()
      NotificationManager.success('Partner ignored successfully.')
    } catch (error) {
      NotificationManager.error(error.message)
    }
  }

  const togglePartnerAccess = async shouldPause => {
    try {
      const success = await toggleProxyAccess(uid, shouldPause)

      if (success) {
        NotificationManager.success(
          `Partner ${shouldPause ? 'paused' : 'resumed'} successfully.`
        )
      }
    } catch (error) {
      NotificationManager.error(error.message)
    }
  }

  const options = []

  if (
    customerType === CustomerType.PROXY_ENTERPRISE &&
    status !== UserStatus.PAUSED
  ) {
    options.push(makeOpt('Trial', confirmProxyEnterpriseApprobation))
  }

  if (
    status === UserStatus.CREATED ||
    status === UserStatus.CONFIRMED ||
    status === UserStatus.KYC_CONFIRMED ||
    status === UserStatus.TRIAL
  ) {
    options.push(makeOpt('Approve', confirmApprobation))
    options.push(makeOpt('Reject', confirmRejection))
    options.push(makeOpt('Ignore', confirmIgnoration))
  } else if (status === UserStatus.BANKED || status === UserStatus.PAUSED) {
    const label = status === UserStatus.BANKED ? 'Pause' : 'Resume'

    options.push(
      makeOpt(label, async () => {
        try {
          if (status === UserStatus.BANKED) {
            await pausePartner(uid)
            await togglePartnerAccess(true)
          } else {
            await approvePartner(uid, false)
            togglePartnerAccess(false)
          }
          refreshPartners()
        } catch (error) {
          NotificationManager.error(error.message)
        }
      })
    )
  }

  return (
    <>
      <TrialModal
        isOpen={modalIsOpen}
        closeModal={closeModal}
        trialSettings={trialSettings}
        setTrialSettings={setTrialSettings}
        handleApprove={handleTrial}
      />
      <AdminDropdown options={options} placeholder={status} />
    </>
  )
}

export default Actions
