import './styles.scss'

import { getActivePlans } from 'api/admin/backend'
import { fetchPartners } from 'api/admin/firebase'
import { CustomerType, customerTypeToEmoji, UserStatus } from 'api/types'
import Actions from 'components/Actions'
import PartnerLayout from 'components/Layouts/PartnerLayout'
import PaginationButtons from 'components/PaginationButtons'
import { useCallback, useEffect, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import { useHistory, useLocation } from 'react-router-dom'
import { formatTableDate } from 'utils/dates'
import { isAdmin as isFirebaseAdmin } from 'utils/firebase'
import { getPlanParams } from 'utils/plans'

const PartnerDashboard = () => {
  const history = useHistory()
  const location = useLocation()
  const [partners, setPartners] = useState([])
  const [searchFilters, setSearchFilters] = useState({
    email: '',
    order: 'desc'
  })
  const [pagination, setPagination] = useState({
    limit: 30,
    currentPage: location.state?.currentPage || 0,
    lastPage: -1
  })

  const getUsedBandwidthForActivePlans = async (
    userId,
    customerType,
    status
  ) => {
    try {
      if (
        ![
          CustomerType.PROXY_ENTERPRISE,
          CustomerType.PROXY_SELFSERVICE
        ].includes(customerType)
      ) {
        return ''
      }

      if (
        ![UserStatus.TRIAL, UserStatus.BANKED, UserStatus.PAUSED].includes(
          status
        )
      ) {
        return ''
      }

      const plans = await getActivePlans(userId)

      const usedGb = plans
        .map(plan => {
          const { used, amount } = getPlanParams(plan)

          return `${used}${amount ? ` / ${amount}` : ''}`
        })
        .join('; ')

      return usedGb || ''
    } catch (error) {
      return '0 GB'
    }
  }

  const loadPartners = useCallback(async () => {
    try {
      const list = await fetchPartners({
        ...searchFilters,
        ...pagination
      })

      // Only update pagination if necessary, otherwise every time the pagination state changes,
      // this function is re-created, which triggers the useEffect hook to run again.
      if (
        list.length < pagination.limit &&
        pagination.lastPage !== pagination.currentPage &&
        searchFilters.email === ''
      ) {
        setPagination(prev => ({
          ...prev,
          lastPage: prev.currentPage
        }))
      }

      setPartners(list)

      list.forEach(async partner => {
        const usedGb = await getUsedBandwidthForActivePlans(
          partner.uid,
          partner.customerType,
          partner.status
        )

        setPartners(prev =>
          prev.map(p => (p.uid === partner.uid ? { ...p, usedGb } : p))
        )
      })
    } catch (error) {
      NotificationManager.error(error.message, 'Error while fetching partners.')
    }
  }, [searchFilters, pagination])

  useEffect(() => {
    loadPartners()
  }, [loadPartners])

  const handleFilterChange = e => {
    const { name, value } = e.target

    setSearchFilters(prevFilters => ({
      ...prevFilters,
      [name]: value
    }))
  }

  const handleRowClick = uid => {
    if (window.getSelection().toString()) {
      return
    }

    history.push({
      pathname: `/profile/${uid}`,
      state: { currentPage: pagination.currentPage }
    })
  }

  return (
    <PartnerLayout>
      <div className='dashboard'>
        <table className='dashboard__table'>
          <thead>
            <tr>
              <th className='dashboard__table__header-cell'>Type</th>
              <th className='dashboard__table__header-cell'>
                <input
                  type='text'
                  name='email'
                  className='dashboard__filter-form-input'
                  placeholder='Email'
                  value={searchFilters.email.toLowerCase()}
                  onChange={handleFilterChange}
                />
              </th>
              <th className='dashboard__table__header-cell'>Used GB</th>
              <th className='dashboard__table__header-cell'>Signup Date</th>
              <th className='dashboard__table__header-cell'>Actions</th>
            </tr>
          </thead>
          <tbody>
            {partners.map(partner => {
              const {
                uid: userId,
                email,
                utcTimestamp,
                customerType,
                usedGb
              } = partner

              if (isFirebaseAdmin(userId)) {
                return null
              }

              return (
                <tr
                  key={userId}
                  className='dashboard__table__row'
                  onClick={() => handleRowClick(userId)}
                >
                  <td className='dashboard__table__cell dashboard__table__cell--customer-type'>
                    {customerTypeToEmoji(customerType)}
                  </td>
                  <td className='dashboard__table__cell'>{email}</td>
                  <td className='dashboard__table__cell'>
                    {usedGb ?? 'Loading...'}
                  </td>
                  <td className='dashboard__table__cell'>{`${formatTableDate(
                    utcTimestamp
                  )}`}</td>
                  <td className='dashboard__table__cell'>
                    <button
                      type='button'
                      onClick={e => {
                        e.stopPropagation() // Prevents the event from bubbling up
                        loadPartners()
                      }}
                      className='dashboard__action-button'
                    >
                      <Actions
                        partner={partner}
                        refreshPartners={loadPartners}
                      />
                    </button>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
        <PaginationButtons
          {...{
            pagination,
            setPagination
          }}
        />
      </div>
    </PartnerLayout>
  )
}

export default PartnerDashboard
