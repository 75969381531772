import './styles.scss'

import { CustomerType } from 'api/types'
import Message from 'components/common/Message'
import PartnerLayout from 'components/Layouts/PartnerLayout'
import YourPlan from 'containers/User/YourPlan'
import AuthContext from 'contexts/AuthContext'
import { useContext } from 'react'

const Dashboard = () => {
  const { customerType } = useContext(AuthContext)

  const shouldApplyNoBorder =
    customerType === CustomerType.PROXY_ENTERPRISE ||
    customerType === CustomerType.PROXY_SELFSERVICE

  return (
    <PartnerLayout containerClass='dashboard' noBorder={shouldApplyNoBorder}>
      {customerType === CustomerType.PROXY_ENTERPRISE ||
      customerType === CustomerType.PROXY_SELFSERVICE ? (
        <YourPlan />
      ) : customerType === CustomerType.SDK ? (
        <Message
          title='Massive Dashboard'
          description="<br />We're currently using an external Metabase dashboard. Click the button below to open it.<br /><br />"
          redirect={{
            label: 'Go to Metabase',
            href: 'https://massive.metabaseapp.com/'
          }}
        />
      ) : null}
    </PartnerLayout>
  )
}

export default Dashboard
