const FormField = {
  NAME: 'name',
  DESC: 'description',
  BILLING_CYCLE: 'billing_cycle_months',
  AUTORENEW: 'autorenew_triggers',
  CHARGE_MODE: 'charge_mode',
  MIN_GB_TO_BE_CHARGED: 'min_charged_gbs',
  MAX_GB: 'allowed_gbs',
  ROLLOVER: 'roll_over_cycles',
  TIERS: 'tiers',
  ASSIGN_TO: 'assigned_to'
}

export default FormField
