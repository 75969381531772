import { makePostRequest } from 'utils/serverless'

export const sendWelcomeEmail = async uid => {
  const response = await makePostRequest('sendWelcomeEmail', {
    params: {
      uid
    }
  })

  return response
}

export const sendCheckoutDetails = async (planId, userIds) => {
  const response = await makePostRequest('sendCheckoutDetails', {
    params: {
      planId,
      userIds
    }
  })

  return response
}

export default sendWelcomeEmail
