import { initializeApp } from 'firebase/app'

const firebaseConfig = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG)
const firebaseApp = initializeApp(firebaseConfig)

export function isAdmin(uid) {
  return uid === process.env.REACT_APP_ADMIN_UID
}

export const filterObjectEntries = userProfileObject =>
  Object.entries(userProfileObject).reduce(
    (acc, [key, value]) =>
      value === undefined ? acc : { ...acc, [key]: value },
    {}
  )

export const AuthErrorMessages = {
  'auth/expired-action-code': 'Reset code expired.',
  'auth/invalid-action-code': 'Reset code invalid/already used.',
  'auth/user-disabled': 'Account disabled by an administrator.',
  'auth/user-not-found': 'Incorrect email/password.',
  'auth/weak-password': 'Password too weak!',
  'auth/email-already-in-use': 'Email already in use.',
  'auth/invalid-email': 'Invalid email format.',
  'auth/wrong-password': 'Incorrect email/password.',
  'auth/user-token-expired': 'You need to sign in again.'
}

export const getMsgFromException = e => {
  if (!e || !e.message) {
    return 'Unknown error occurred'
  }

  const keyFound = Object.keys(AuthErrorMessages).find(key =>
    e.message.includes(`(${key})`)
  )

  if (keyFound) {
    return AuthErrorMessages[keyFound]
  }

  return e.message
}

export default firebaseApp
