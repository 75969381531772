import Banner from 'components/common/Banner'
import SectionTitle from 'components/SectionTitle'

import InvoiceCard from '../InvoiceCard'

const sortInvoicesByPaidDate = invoices => {
  return invoices?.sort((a, b) => {
    return new Date(b.paid_at) - new Date(a.paid_at)
  })
}

const InvoiceList = ({
  arePastInvoicesLoading,
  pastInvoices,
  upcomingInvoice
}) => {
  const sortedPastInvoices = sortInvoicesByPaidDate(pastInvoices)

  return (
    <>
      {upcomingInvoice && (
        <>
          <SectionTitle title='Upcoming Invoice' />

          <InvoiceCard
            key={upcomingInvoice.id || 'upcoming-invoice-id'}
            invoice={upcomingInvoice}
            isUpcoming
          />
        </>
      )}

      <SectionTitle title='Past Invoices' />

      {arePastInvoicesLoading ? (
        <Banner message='Loading invoices...' />
      ) : sortedPastInvoices?.length ? (
        sortedPastInvoices.map(invoice => (
          <InvoiceCard key={invoice.id} invoice={invoice} />
        ))
      ) : (
        <Banner message='No invoices have been issued yet.' />
      )}
    </>
  )
}

export default InvoiceList
