// This has Auth States and Routes bundled together. Let's rewrite it as two files later.

import 'react-notifications/lib/notifications.css'

import { CustomerType, UserStatus } from 'api/types'
import { getUserProfile, isLoggedIn } from 'api/user/firebase'
import AuthContext from 'contexts/AuthContext'
import useTapfiliate from 'hooks/useTapfiliate'
import { lazy, Suspense, useEffect, useState } from 'react'
import { NotificationContainer, NotificationManager } from 'react-notifications'
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation
} from 'react-router-dom'
import useAnalytics from 'utils/analytics'
import { isAdmin as isFirebaseAdmin } from 'utils/firebase'

import { CreatePlanForm, Partners, PlanManager, UserProfile } from './Admin'
import Login from './Authentication/Login'
import PasswordReset from './Authentication/PasswordReset'
import UserEmailConfirmation from './Authentication/UserEmailConfirmation'
import Dashboard from './User/Dashboard'
import Invoices from './User/Invoices'
import Profile from './User/Profile'

const PrepaidPlans = lazy(() => import('./User/PrepaidPlans'))
const CreateAccountForm = lazy(() => import('./User/CreateAccountForm'))
const KnowYourCustomerForm = lazy(() => import('./User/KnowYourCustomerForm'))
const Checkout = lazy(() => import('./User/Checkout'))
const Terms = lazy(() => import('./Legal/Terms'))
const Eula = lazy(() => import('./Legal/License'))
const Privacy = lazy(() => import('./Legal/Privacy'))
const VerifyEmailChange = lazy(() => import('../handlers/VerifyEmailChange'))
const ResendConfirmationEmailForm = lazy(() =>
  import('./Authentication/ResendConfirmationEmailForm')
)

const useRoutes = () => {
  const [routes, setRoutes] = useState()
  const history = useHistory()
  const location = useLocation()

  useEffect(() => {
    ;(async () => {
      try {
        const uid = await isLoggedIn()
        let email
        let status
        let walletId
        let isAdmin
        let customerType
        let additionalInfo
        let planId
        let pendingPlanId

        if (uid) {
          ;({
            email,
            status,
            walletId,
            customerType,
            additionalInfo,
            planId,
            pendingPlanId
          } = await getUserProfile(uid))

          isAdmin = isFirebaseAdmin(uid)

          if (isAdmin && history.location.pathname.startsWith('/login')) {
            history.replace('/admin/dashboard')
          }
        }

        const isUserLoggedIn = !!uid
        const redirectToEmailConfirmation =
          isUserLoggedIn && status === 'created'

        const template = (
          <Suspense fallback={<></>}>
            <Switch>
              <Route key='terms-route' path='/terms' component={Terms} />
              <Redirect key='eula-redirect' from='/license' to='/eula' />
              <Route key='eula-route' path='/eula' component={Eula} />
              <Route key='privacy-route' path='/privacy' component={Privacy} />
              <Route
                key='verify-email-change-route'
                path='/email-update'
                component={VerifyEmailChange}
              />
              {status === UserStatus.CREATED && (
                <Route
                  key='resend-confirmation-email-route'
                  path='/confirmation'
                  component={ResendConfirmationEmailForm}
                />
              )}
              {[UserStatus.CREATED, UserStatus.CONFIRMED].includes(status) && (
                <Route
                  key='email-confirmation-route'
                  path='/email-confirmation'
                  component={UserEmailConfirmation}
                />
              )}
              {!redirectToEmailConfirmation && (
                <Route
                  key='redirect-plans-1'
                  path='/plans'
                  component={PrepaidPlans}
                />
              )}
              {redirectToEmailConfirmation && [
                <Redirect
                  key='redirect-login'
                  to='/email-confirmation'
                  from='/login'
                />,
                <Redirect
                  key='redirect-plans-2'
                  to='/email-confirmation'
                  from='/plans'
                />
              ]}
              {isAdmin && [
                <Route key='default-admin-redirect' exact path='/'>
                  <Redirect to='/admin/dashboard' />
                </Route>,
                <Route key='deprecated-admin-dashboard' exact path='/admin'>
                  <Redirect to='/admin/dashboard' />
                </Route>,
                <Route
                  key='admin-plans'
                  path='/admin/plans'
                  component={PlanManager}
                />,
                <Route
                  key='admin-preview-plan'
                  path='/admin/plan/:id'
                  component={CreatePlanForm}
                />,
                <Route
                  key='admin-create-plan'
                  path='/admin/plan'
                  component={CreatePlanForm}
                />,
                <Route
                  key='admin-dashboard'
                  path='/admin/dashboard'
                  component={Partners}
                />,
                <Route
                  key='admin-user-profile'
                  path='/profile/:uid'
                  component={UserProfile}
                />,
                <Redirect key='redirect-admin' to='/admin/dashboard' />
              ]}
              {isUserLoggedIn
                ? [
                    <Route key='default-user-path' exact path='/'>
                      <Redirect to='/dashboard' />
                    </Route>,
                    customerType === CustomerType.PROXY_SELFSERVICE && (
                      <Route
                        key='user-kyc'
                        path='/kyc'
                        component={KnowYourCustomerForm}
                      />
                    ),
                    <Route key='deprecated-your-plan' path='/your-plan'>
                      <Redirect
                        to={{
                          pathname: '/dashboard',
                          search: location.search
                        }}
                      />
                    </Route>,
                    <Route
                      key='user-profile'
                      path='/profile'
                      component={Profile}
                    />,
                    <Route
                      key='user-checkout-with-plan'
                      path='/checkout/:id'
                      component={Checkout}
                    />,
                    <Route
                      key='user-checkout'
                      path='/checkout'
                      component={Checkout}
                    />,
                    <Route
                      key='user-dashboard'
                      path='/dashboard'
                      component={Dashboard}
                    />,
                    <Route
                      key='user-invoices'
                      path='/invoices'
                      component={Invoices}
                    />,
                    <Redirect key='redirect-logged-in' to='/' />
                  ]
                : [
                    <Route
                      key='create-account-self-service'
                      path='/create-account'
                      render={props => (
                        <CreateAccountForm
                          {...props}
                          customerType={CustomerType.PROXY_SELFSERVICE}
                        />
                      )}
                    />,
                    <Route
                      key='create-account-enterprise'
                      path='/create-account-enterprise'
                      render={props => (
                        <CreateAccountForm
                          {...props}
                          customerType={CustomerType.PROXY_ENTERPRISE}
                        />
                      )}
                    />,
                    <Route
                      key='create-account-sdk'
                      path='/create-account-sdk'
                      render={props => (
                        <CreateAccountForm
                          {...props}
                          customerType={CustomerType.SDK}
                        />
                      )}
                    />,
                    <Route key='login-route' path='/login' component={Login} />,
                    <Route
                      key='password-reset-route'
                      path='/reset'
                      component={PasswordReset}
                    />,
                    <Redirect key='redirect-logged-out' to='/plans' />
                  ]}
            </Switch>
          </Suspense>
        )

        setRoutes(
          uid ? (
            <AuthContext.Provider
              value={{
                uid,
                email,
                status,
                walletId,
                isAdmin,
                customerType,
                additionalInfo,
                planId,
                pendingPlanId
              }}
            >
              {template}
            </AuthContext.Provider>
          ) : (
            template
          )
        )
      } catch (error) {
        const Sentry = await import('@sentry/react')

        Sentry.captureException(error)
        NotificationManager.error(
          error.message,
          'Something went wrong – please try again.'
        )
      }
    })()
  }, [history, location])

  return routes
}

const Routes = () => {
  useAnalytics()
  useTapfiliate()

  return (
    <>
      {useRoutes()}
      <NotificationContainer />
    </>
  )
}

export default Routes
