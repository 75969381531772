const CustomerType = Object.freeze({
  PROXY_ENTERPRISE: 'proxy_enterprise',
  PROXY_SELFSERVICE: 'proxy_selfservice',
  SDK: 'sdk'
})

export const customerTypeToEmoji = customerType => {
  switch (customerType) {
    case CustomerType.PROXY_ENTERPRISE:
      return '🏛️'
    case CustomerType.PROXY_SELFSERVICE:
      return '👨‍💻'
    case CustomerType.SDK:
      return '🛠️'
    default:
      return '🤷'
  }
}

export default CustomerType
