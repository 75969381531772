import './styles.scss'

import PartnerLayout from 'components/Layouts/PartnerLayout'
import usePastInvoices from 'hooks/usePastInvoices'
import useUpcomingInvoice from 'hooks/useUpcomingInvoice'

import InvoiceList from './InvoiceList'

const Invoices = () => {
  const { isLoading: arePastInvoicesLoading, invoices: pastInvoices } =
    usePastInvoices()
  const { invoice: upcomingInvoice } = useUpcomingInvoice()

  return (
    <PartnerLayout containerClass='invoices' noBorder>
      <InvoiceList
        {...{
          arePastInvoicesLoading,
          pastInvoices,
          upcomingInvoice
        }}
      />
    </PartnerLayout>
  )
}

export default Invoices
