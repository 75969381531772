import './styles.scss'

import Button from 'components/Button'
import Input from 'components/Input'
import SectionTitle from 'components/SectionTitle'
import { useState } from 'react'
import { NotificationManager } from 'react-notifications'

const ApiToken = ({ value }) => {
  const actualValue = value || 'Contact help@joinmassive.com'

  const [showValue, setShowValue] = useState(false)

  const copyToClipboard = () => {
    navigator.clipboard.writeText(actualValue)
    NotificationManager.info('The token was copied to your clipboard.')
  }

  const toggleVisibility = () => {
    setShowValue(!showValue)
  }

  return (
    <>
      <SectionTitle title='SDK API token' />
      <div className='api-token'>
        <Input
          type={showValue ? 'text' : 'password'}
          name='api-key'
          value={actualValue}
          disabled
          autoComplete='api-key'
        />
        <Button
          type='button'
          label={showValue ? 'Hide' : 'Show'}
          onClick={toggleVisibility}
          inline
        />
        <Button type='button' label='Copy' onClick={copyToClipboard} inline />
      </div>
    </>
  )
}

export default ApiToken
