import { PlanType } from 'api/types'

import { formatCurrency, formatCurrencyRemovingTrailingZeros } from './currency'
import { calculateExpiration, calculateExpirationDay } from './dates'

export const bytesToGB = (bytes = 0) => bytes / 1000 ** 3

export const gbToTb = gb => {
  if (typeof gb !== 'number') {
    return ''
  }

  let formattedValue = gb >= 1000 ? gb / 1000 : gb

  // Remove trailing zeros if any
  formattedValue = parseFloat(formattedValue.toFixed(3)).toString()

  const unit = gb >= 1000 ? 'TB' : 'GB'

  return `${formattedValue} ${unit}`
}

export const calculatePlanPrice = (usedBytes, minChargedGb, tiers) => {
  let usedGb = bytesToGB(usedBytes)

  usedGb = usedGb < minChargedGb ? minChargedGb : usedGb

  let remainingGB = usedGb
  let previousUpperBoundGb = 0
  let price = 0

  for (let i = 0; i < tiers.length; i++) {
    const { price_per_unit: pricePerGb, up_to_unit: upperBoundGb } = tiers[i]
    const tierGb = upperBoundGb ? upperBoundGb - previousUpperBoundGb : Infinity

    if (remainingGB > tierGb) {
      price += tierGb * pricePerGb
      remainingGB -= tierGb
    } else {
      price += remainingGB * pricePerGb
      break
    }

    previousUpperBoundGb += tierGb
  }

  return price
}

const toUsd = value => formatCurrency(value, 2, 2)

const toUsdRemovingTrailingZeros = value =>
  formatCurrencyRemovingTrailingZeros(value, 2, 2)

const getPlanType = (id, planId, minChargedGb, maxBandwidth) => {
  if (
    id?.toLowerCase().includes(PlanType.TRIAL) ||
    planId?.toLowerCase().includes(PlanType.TRIAL)
  ) {
    return PlanType.TRIAL
  }

  if (!minChargedGb && !maxBandwidth) {
    return PlanType.PAYG
  }

  if (minChargedGb > 0 && maxBandwidth > 0) {
    return PlanType.PREPAID
  }

  return PlanType.CUSTOM
}

const getDefaultExpiration = () => {
  const today = new Date()

  today.setDate(today.getDate() + 31)

  return today
}

export const getPlanParams = (plan, today = new Date()) => {
  if (!plan) {
    return null
  }

  const { id, plan_id: planId, name, details } = plan

  const {
    pricing_unit: pricingUnit,
    used_bytes: usedBytes,
    min_charged_units: minChargedGb,
    allowed_units: maxBandwidth,
    expires_at: expiresAt,
    tiers
  } = details || plan

  const effectiveExpiresAt = expiresAt || getDefaultExpiration()
  const expirationDays = calculateExpiration(today, effectiveExpiresAt)
  const expirationDate = calculateExpirationDay(effectiveExpiresAt)

  const usedGb = bytesToGB(usedBytes)
  const used = gbToTb(usedGb)

  const price = calculatePlanPrice(usedBytes, minChargedGb, tiers)

  const planType = getPlanType(id, planId, minChargedGb, maxBandwidth)

  let priceText = []

  if (tiers.length === 1) {
    const pricePerUnit = toUsdRemovingTrailingZeros(tiers[0].price_per_unit)

    if (pricePerUnit) {
      priceText.push(`${pricePerUnit} / ${pricingUnit}`)
    }
  } else if (tiers.length > 1) {
    priceText = tiers.map((tier, index) => {
      const pricePerUnit = toUsdRemovingTrailingZeros(tier.price_per_unit)

      const previousUpToUnit = gbToTb(tiers[index - 1]?.up_to_unit || 0)
      const label =
        tier.up_to_unit === null
          ? `${pricePerUnit} > ${previousUpToUnit}`
          : `${pricePerUnit} < ${gbToTb(tier.up_to_unit)}`

      return label
    })
  }

  let amount = ''
  const minChargedBandwidth = gbToTb(minChargedGb)
  const lastTier = tiers[tiers.length - 1]

  if (minChargedGb) {
    let usePlus = false

    if (!maxBandwidth || maxBandwidth > minChargedGb) {
      usePlus = true
    }

    amount = minChargedBandwidth.replace(/ /g, usePlus ? '+ ' : ' ')
  } else {
    amount = lastTier ? gbToTb(lastTier.up_to_unit) : ''
  }

  let checkoutAmount = usedGb < minChargedGb ? minChargedGb : usedGb

  checkoutAmount = gbToTb(checkoutAmount)

  return {
    planType,
    minChargedBandwidth,
    amount,
    checkoutAmount,
    maxBandwidth,
    used,
    price: toUsd(price),
    priceText,
    label: `${name}; ${amount || 'Pay As You Go'}; ${priceText.join(', ')}`,
    totalAmount: toUsd(price),
    expirationDays,
    expirationDate
  }
}

export const findPlanById = (plans, id) => plans.find(item => item.id === id)

export const pluralize = (count, noun, suffix = 's') =>
  `${count} ${noun}${count !== 1 ? suffix : ''}`
